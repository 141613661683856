import React, {Component, Fragment} from 'react';
import axios from "axios";
import Swiper from "react-id-swiper";

import {BallBeat} from "react-pure-loaders";

class SalesBanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingSwip: true,
            banners: [],
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })

        axios.get(window.$urlName + 'banners', {
            params: {
                type: 'sales',
            }
        }).then((response) => {
            this.setState({
                banners: response.data.data,
                loadingSwip: false
            });
        });
    }

    render() {
        const homeParamsSwiper = {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
        };

        let banners =
            this.state.banners.map((banner, index) => {
                return (
                    <div className="homeSlide mdiafSlide" style={{backgroundImage: `url(${banner.image})`}} key={index}></div>
                )
            });
        return (
            <Fragment>
                <div className="homeSwiper">

                    {this.state.banners.length !== 0 ? (

                        <Swiper {...homeParamsSwiper}>
                            {banners}
                        </Swiper>
                    ) : <div className="loadingSec" style={{marginTop: "200px", marginBottom: "200px"}}>
                        <BallBeat
                            color={'white'}
                            loading={this.state.loadingSwip}
                        />
                    </div>}

                </div>
            </Fragment>
        )
    }
}

export default SalesBanner