import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import TopCustomersBanner from "./topCustomersBanner";

class topCustomersTerms extends Component {    
    render() {
        return (
            <Fragment>
                <TopCustomersBanner></TopCustomersBanner>
                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar top-bar">
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/top-customers`}}>
                                <Translate content="topCustomers"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items mudiaf-items-active">
                            <Link to={{pathname: `/top-customers-terms`}}>
                                <Translate content="terms"/>
                            </Link>
                        </div>                        
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                
                    <div className="container">        
                        <div className="row topCustomers">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <p>برنامج كبار العملاء الخاص بشركة الرومانسية المحدودة، هو برنامج مزايا تديره شركة الرومانسية 
المحدودة وستحظى بمزايا عندما يتم تأهيلك لتكون ضمن كبار عملاء مطاعم الرومانسية وذلك عند إظهار 
رقم عضويتك الخاصة بك عند الشراء.  
</p>
                                <h2>شروط وأحكام عامة: </h2>
                                <p>هذه الشروط والأحكام تتعلق بالعضوية للحصول على المزايا تبعا للبرنامج عند التأهل لاكتساب العضوية.</p>
                                <ul>
                                    <li>تجاوز مبيعاته حسب نسبة تحددها إدارة المبيعات في الشركة سنوياً.  </li>
                                    <li>مدة العضوية سنة يتم تجديدها حسب مشتريات العميل ويخضع للشروط والأحكام وفي حال عدم موافقتك 
على أي من البنود والشروط فإننا ننصحك بسحب وإلغاء عضويتك. </li>
                                    <li>يحصل العميل على أحد المزايا التالية في حال تأهله لعضوية كبار العملاء: 
                                        <ul>
                                            <li>ذبيحة بقيمة: 2000 ريال بحد اقصى.</li>
                                            <li>بوفية مفتوح بقيمة: 3000 ريال بحد اقصى.</li>
                                        </ul>
                                    </li>

                                    <li>باستخدام رقم عضويتك في كبار العملاء الخاص بك في أي من فروع الرومانسية بغرض كسب المزايا 
فأنت تلقائياً تعطي موافقتك على البرنامج والبنود والشروط الخاضعة للتعديل من وقت إلى آخر. </li>

                                    <li>يحق لشركة الرومانسية المحدودة أن تقوم بسحب أو إلغاء أي أو جميع عضويات كبار العملاء الصادرة 
ورفض منح المزايا وسحبها، ورفض حق استرداد المزايا دون إشعار مسبق. وذلك نتيجة أي خرق لهذه 
الشروط والأحكام أو عدم سداد قيمة المشتريات. </li>

                                    <li>إن عضويتك في البرنامج يمكن أن تُسحب أو تُرفض إذا كنت طرفا في أي عملية تزوير أو سرقة أو غش 
بسبب أو بدون سبب ويتم هذا السحب أو الرفض دون إشعار. ويمكن إلغاء العضوية أيضاً إذا تبين بأنك كنت 
طرفا في أي سوء استعمال لعضوية برنامج كبار العملاء. 
 في حال حدوث ذلك فجميع المزايا الموجودة في حساب عضويتك سوف تعتبر ملغاة ولن تتمكن من 
استعادتها مرة أخرى. </li>

                                    <li> يحق لشركة الرومانسية أن تقوم بتعديل أو وقف البرنامج أو تغيير أو إلغاء أو 
سحب أي من الشروط والأحكام حسب تقديرها ودون الحاجة لإعطاء أي سبب من الأسباب في أي وقت من 
الأوقات. </li>

                                    <li>يحق لشركة الرومانسية المحدودة إيقاف العضوية في البرنامج - الحالية أو الجديدة - لفترة مؤقتة أو 
دائمة.  
وستعمل شركة الرومانسية المحدودة جاهدة لتحديث ذلك وإضافة معلومات دقيقة على موقعها 
www.alromansiah.com أو تطبيق الرومانسية حول هذا التغيير أو الإلغاء أو الإضافة أو السحب أو 
التعديل.  
تقع عليك مسئولية قراءة تلك البنود والشروط بتمعن وقبولها. </li>

                                    <li>يمكن للمزايا والعروض المقدمة إليك من خلال البرنامج أن تتغير أو تُسحب دون إعلان مسبق عن ذلك. 
ولن تتحمل شركة الرومانسية المحدودة أي مسؤولية نتيجة هذه الإجراءات. </li>

                                    <li>يحق لشركة الرومانسية المحدودة أن تعدل الطريقة التي يتم اكتساب العضوية فيها بما في ذلك الشروط 
والمزايا. </li>

                                    <li>أنت مسئول عن إعلام مركز خدمة كبار العملاء عن أي تغيير في عنوانك أو تفاصيل طرق الاتصال بك 
ولن تكون شركة الرومانسية مسئولة عن أي استعمال غير قانوني أو سوء استعمال لعضوية كبار العملاء. </li>

                                    <li>عند موافقتك الانضمام لبرنامج كبار العملاء بعد تأهلك لكسب لعضوية، فإنك تمنح شركة الرومانسية 
المحدودة موافقتك الغير مشروطة لجمع واستخدام والاحتفاظ بالمعلومات الموجودة في عضوية كبار العملاء 
وكذلك معلوماتك الشخصية الأخرى بما في ذلك وبدون حصر بريدك الالكتروني وعناوينك وأرقام الاتصال بك 
وتاريخ الميلاد وتفاصيل مشترياتك وما شابه، وذلك للأغراض التالية: - 
                                        <ul>
                                            <li>للتأكد من فعالية عمل البرنامج واستحقاق المزايا.</li>
                                            <li>لتقديم معلومات حول برنامج كبار العملاء.</li>
                                            <li>لتطوير/ لتقديم منتجات وخدمات جديدة.</li>
                                            <li>لأغراض المحاسبة والتدقيق المالي.</li>
                                            <li>لأغراض التسويق وأبحاث وتحليل سلوك المستهلك.</li>
                                            <li>لغرض الشفافية كما هو مطلوب قانونياً.</li>
                                            <li>لإبلاغك وإرسال العروض والخدمات والمنتجات والمميزات المقدمة من شركة الرومانسية المحدودة.</li>
                                            <li>للمساعدة في تخطيط وتطوير وتشغيل البرنامج.</li>
                                        </ul>
                                    </li>

                                    <li>موافقتك المذكورة سوف تستمر فعّالة حتى تقوم بسحب الموافقة وذلك بالاتصال على مركز الاتصال 
920000144.  
سحب الموافقة يعني عدم استمرار الخدمة المقدمة لك وأيضا تخويلا لشركة الرومانسية لإلغاء عضويتك 
بصورة فردية. </li>

                                    <li>شركة الرومانسية المحدودة لن تكون مسئولة قانونياً عن أي خسارة أو ضرر، سواء بصورة مباشرة 
أو غير مباشرة نتيجة للإلغاء أو التغيير في البرنامج أو أي المميزات أو الفوائد المتاحة للأعضاء. </li>

                                    <li>تعتبر الشروط والأحكام المنشورة من وقت لآخر في موقع شركة الرومانسية الالكتروني 
www.alromansiah.com نهائية وملزمة وهي تبطل هذه الشروط والأحكام والمعلومات الأخرى 
المقدمة هنا عن البرنامج. </li>

                                    <li>في حال وجود عطل فني في النظام، وفقدان جميع أو بعض بيانات العملاء لأي سبب من الأسباب، فإن 
شركة الرومانسية المحدودة لا تتحمل أي مسؤولية تجاه ذلك. 
ملاحظة: في حال تم تفعيل البرنامج في فروع خارج المملكة: - </li>
                                    
                                    <li>هذه الشروط والأحكام تحكمها قوانين الدولة التي تصدر بطاقة عضوية كبار العملاء فيها وأي نزاع 
يمكن إحالتها إلى محاكم هذه الدولة. </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default topCustomersTerms