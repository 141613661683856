import React, {Component, Fragment} from "react"
import Translate from "react-translate-component";
import axios from "axios";
import logo from "./component/assest/img/fullLogo.png";

let translate = require('counterpart');

class Invoice extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            orderId: window.location.href.split('/')[4].split("-")[0],
            orderCode: window.location.href.split('/')[4].split("-")[1],
            statusId: '',
            branchAr: '',
            branchEn: '',
            branchId: '',
            totalPrice: 0,
            currAr: '',
            currEn: '',
            createdAt: '',
            products: [],
    
            orderTypeAr: "",
            orderTypeEn: "",
            branchPhone: "",
            driverPhone: "",
            originalPrice: 0,
            discount: 0,
            deliveryPrice: 0,
            tax: 0,
            taxValue: 0,
            timeRemind: 0,
            now: false,
            later: false,
            date: "",
            time: "",
            notes: "",
            paymentMethod: ""
        };
    }

    async componentDidMount(){
        await axios.get(window.$urlName + 'orders/invoice/' + this.state.orderId+ '/'+ this.state.orderCode, {
       
        }).then((response) => {
            if (response.data.data.now === true) {
                if (response.data.data.remainingTimeInSeconds === 0 || response.data.data.remainingTimeInSeconds === null) {
                    this.setState({
                        now: false,
                    })
                } else {
                    this.setState({
                        now: true,
                        later: false
                    })
                }
            } else {
                this.setState({
                    now: false,
                    later: true,
                })
            }

            this.setState({
                timeRemind: response.data.data.remainingTimeInSeconds,
                date: response.data.data.date,
                time: response.data.data.time,
                branchAr: response.data.data.branchArName,
                branchEn: response.data.data.branchEnName,
                branchId: response.data.data.branchId,
                totalPrice: response.data.data.totalPrice,
                currAr: response.data.data.currencyAr,
                currEn: response.data.data.currencyEn,
                createdAt: response.data.data.createdAt,
                products: response.data.data.products,
                branchPhone: response.data.data.branchPhone,
                driverPhone: (response.data.data.driverPhone) ? "tel:" + response.data.data.driverPhone : "",
                originalPrice: response.data.data.originalPrice,
                discount: response.data.data.discount,
                deliveryPrice: response.data.data.deliveryPrice,
                tax: response.data.data.tax,
                taxValue: response.data.data.taxValue,
                typeId: response.data.data.typeId,
                notes: response.data.data.notes,
                paymentMethod: response.data.data.paymentMethod,
                statusId: response.data.data.statusId,

                loading: false,
            });

            if (response.data.data.typeId === 1) {
                this.setState({
                    orderTypeAr: "تستلم من الفرع",
                    orderTypeEn: "Branch Pickup",
                    withDriver: false,
                    inProgressState: true,
                })    
            }
            else if (response.data.data.typeId === 3) {
                this.setState({
                    orderTypeAr: "حملة شاركهم",
                    orderTypeEn: "Share them campaign",
                    withDriver: false,
                    inProgressState: false,
                })
            }
            else {
                this.setState({
                    orderTypeAr: "نجيك البيت",
                    orderTypeEn: "Home Delivery",
                    withDriver: true,
                    inProgressState: true,
                })
            }
        }).catch(function (error) {
            if (error.response.status === 422) {
               window.location.pathname = "/404"
            }
        })
    };

    render() {
        let products = this.state.products.map((product, index) => {
            return (
                <tr key={index} id={product.productId}>
                    {translate.getLocale() === 'ar' ? 
                        <th>
                            {product.productArName} - {product.sizeArName} - 
                            {product.additionals.length !== 0 ? product.additionals.map((item) => item.additionalArName) : " "}
                            {product.extras.length !== 0 ? product.extras.map((item) => item.arName + " ") : "  "}
                        </th> 
                        :
                        <th>
                            {product.productEnName} - {product.sizeEnName} - 
                            {product.additionals.length !== 0 ? product.additionals.map((item) => item.additionalEnName) : " "}
                            {product.extras.length !== 0 ? product.extras.map((item) => item.enName + " ") : " "}
                        </th>}

                    <th className="arialFont">{product.quantity}</th>
                    <th className="arialFont">{(product.price).toFixed(2)}</th>
                </tr>
            )
        });

        return (
            <Fragment>
                <div className="container-fluid finalOrder" style={{width:"50%" ,maxWidth: "500px",minWidth: "400px",margin:"30px auto"}}>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 processRightSide">
                            <div style={{textAlign:"center",marginBottom:"10px"}}>
                                <img src={logo} style={{width:"90%"}}/>
                            </div>
                            <div className="orderTable">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="branch_name" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        {translate.getLocale() === 'ar' ? <label>{this.state.branchAr}</label> :
                                            <label>{this.state.branchEn}</label>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="branch_phone" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label>{this.state.branchPhone}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="commercialRegister" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label>87271-2</label>                                    
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="taxNumber" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label>300057172200003</label>                                    
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="order_id" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label>{this.state.orderCode}</label>                                    
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="order_type" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        {translate.getLocale() === 'ar' ? <label>{this.state.orderTypeAr}</label> :
                                            <label>{this.state.orderTypeEn}</label>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="order_received" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label>{this.state.createdAt}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="paymentMethod" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label>{this.state.paymentMethod}</label>
                                    </div>
                                </div>

                                {this.state.notes !== "" ?
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="notes" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label>{this.state.notes}</label>
                                    </div>
                                </div>
                                :""}

                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="orderTable">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <Translate content="name" component="th"/>
                                                            <Translate content="quantity" component="th"/>
                                                            <Translate content="price" component="th"/>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {products}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="price" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label><span className="arialFont">{parseFloat(this.state.originalPrice).toFixed(1)} </span>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</label>
                                    </div>
                                </div>

                                {this.state.discount > 0 ?
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="discount" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label><span className="arialFont">{parseFloat(this.state.discount).toFixed(1)} </span>{translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</label>
                                    </div>
                                </div>:""}

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><label><Translate content="tax"/> ({this.state.tax} %)</label></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label><span className="arialFont">{this.state.taxValue}</span> {translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <strong><Translate content="total_cost" component="label"/></strong>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6" style={{maxWidth:"50%"}}>
                                        <label><span className="arialFont">{parseFloat(this.state.totalPrice).toFixed(1)}</span> {translate.getLocale() === 'ar' ?
                                            <span>{this.state.currAr}</span> :
                                            <span>{this.state.currEn}</span>}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Invoice