import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import SalesBanner from "./salesBanner";
import './component/assest/css/swiper.min.css';
import 'react-image-lightbox/style.css';

import {BallBeat} from "react-pure-loaders";

class SalesClients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: localStorage.getItem("token"),
            imagesArr: [],
            loading:false,
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };
        axios.get(window.$urlName + 'sales/clients', {
            headers
        }).then((response) => {
            this.setState({
                imagesArr: response.data.data.salesClients,
                loading:false
            })
        }).catch(function (error) {
             //console.log(error.response)
        });
    }

    render() {
        let images = this.state.imagesArr.map((image, index) => {
            return (
                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" key={index}>
                    <div className="branchSingleCard">
                        <div className="branchImg" style={{backgroundImage: `url(${image.image})`}}>
                        </div>            
                    </div>
                </div>
            )
        });
        return (
            <Fragment>
                <SalesBanner></SalesBanner>

                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies`}}>
                                <Translate content="companies_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-services`}}>
                                <Translate content="companies_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items companies-items mudiaf-items-active">
                            <Link to={{pathname: `/companies-clients`}}>
                                <Translate content="companies_clients"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-contact`}}>
                                <Translate content="contact_us"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec" style={{background:"#f5f6fa"}}>
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="hospitableInfo">
                                <Translate content="companies_clients" component="h1"/>
                            </div>
                        </div>
                        <div className="row">
                        {images.length>0 ? 
                            <div className="container hospitableSwiper">
                                <div className="row salesGallery">
                                    {images}
                                </div>
                            </div>
                        : 
                            <Translate content="noResults" component="p" className="no_results"/>
                        }
                        
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SalesClients