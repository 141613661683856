import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import {BallBeat} from "react-pure-loaders";

let translate = require('counterpart');

class SalesInquire extends Component {
    constructor(props) {
        super(props)
        let loggedIn = true;

        this.state = {
            token: localStorage.getItem("token"),
            menuId: window.location.href.split('/')[4],
            menuNameEn: "",
            menuNameAr: "",
            image:"",
            pricePersonSAR: 0,
            loading:false,
            companyName: "",
            notes: "",
            salesInquireSuccessMsg: localStorage.getItem("salesInquireSuccessMsg"),

            loggedIn
        }
    }

    componentDidMount() {
        if(localStorage.getItem("salesInquireSuccessMsg") === "true"){
            localStorage.setItem("salesInquireSuccessMsg", "false");
        }

        let headers = {
            'Accept': 'application/json',
        };

        axios.get(window.$urlName + 'sales/menus/'+ this.state.menuId, {
            headers
        }).then((response) => {
            this.setState({
                pricePersonSAR: response.data.data.pricePersonSAR,
                menuNameEn: response.data.data.enName,
                menuNameAr: response.data.data.arName,
                image: response.data.data.image,
                loading:false
            })
        }).catch(function (error) {
            //window.location.pathname = "/404"
        });
    }

    inquireSubmit = (values) => {
        axios.post(window.$urlName + 'sales/inquires', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then((response) => {
            localStorage.setItem("salesInquireSuccessMsg", "true");
            this.setState({
                redirect: true,
            })
        }).catch(error => {
            if (error.response.status === 401) {
                this.setState({
                    loggedIn: false,
                })
            } else {
                this.getError(error.response.data.error)
            }
        });
    };

    handelSubmit = (e) => {
        e.preventDefault();
        if (this.state.token == null) {
            this.setState({
                loggedIn: false
            })
        }else {
            let values = {
                notes: this.state.notes,
                company_name: this.state.companyName,
                menu_id: this.state.menuId,
            };
            if(this.state.notes !== "" && this.state.companyName !== "" && this.state.menuId){
                this.inquireSubmit(values);
            }else{
                alert("يرجي اختيار القائمة لكي تتمكن من الطلب");
            }
        }
    };

    handleChange = (e) => {
        this.setState ({
            [e.target.id]: e.target.value
        });
    };

    getError = (msg) => {
        this.setState({
            ErrorMsgAr: "حدث خطأغير متوقع",
            ErrorMsgEn: "something went wrong",
            orderErrorMsgModel: true,
            loading: false,            
        })
    };
    
    render() {
        if (this.state.loggedIn === false) {
            const location =
                {pathname: '/sign-in', state: {from: 'salesInquire'}};
            this.props.history.push(location);
        }

        const {redirect} = this.state;

        if (redirect) {
            window.location.pathname = "/companies-inquire"
        }


        return (
            <Fragment>

                <Sticky stickyClassName="stickyFilter">
                        <div className="mudiaf-bar">
                            <div className="mudiaf-items companies-items mudiaf-items-active">
                                <Link to={{pathname: `/companies`}}>
                                    <Translate content="companies_menus"/>
                                </Link>
                            </div>
                            <div className="mudiaf-items companies-items">
                                <Link to={{pathname: `/companies-services`}}>
                                    <Translate content="companies_services"/>
                                </Link>
                            </div>                        
                            <div className="mudiaf-items companies-items">
                                <Link to={{pathname: `/companies-clients`}}>
                                    <Translate content="companies_clients"/>
                                </Link>
                            </div>
                            <div className="mudiaf-items companies-items">
                                <Link to={{pathname: `/companies-contact`}}>
                                    <Translate content="contact_us"/>
                                </Link>
                            </div>
                        </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <div className="container">
            
                        {this.state.salesInquireSuccessMsg === "true" ? 
                            <div className="row">
                                <div className="mudiaf-inquire-success">
                                    <Translate component="p" content="inquireSuccess"/>
                                </div>
                            </div>
                        : 

                        <div className="row">
                        
                            <form onSubmit={this.handelSubmit} style={{width:"50%",minWidth:"320px",margin:"50px auto"}}>
                                <div className="mudiafCheckoutWhite" style={{padding:"30px",borderRadius:"10px"}}>

                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{position: "relative"}}>
                                            <div className="row mealCard">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                                    <div className="branchImg" style={{backgroundImage: `url(${this.state.image})`}}>
                                                    </div>
                                                </div>

                                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                    <div className="branchSingleCardTitle">
                                                        {translate.getLocale() === 'ar' ? <h3>{this.state.menuNameEn}</h3> : <h3> {this.state.menuNameEn} </h3>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                    <div className="branchSingleCardTitle">
                                                        <p><strong><Translate content="person_cost" component="span"/></strong> {this.state.pricePersonSAR} <Translate content="SAR"/></p>
                                                    </div>
                                                </div>

                            
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <Translate attributes={{placeholder: 'enterCompanyName'}} id="companyName" defaultValue={this.state.companyName} onChange={this.handleChange} component="input" required="required"/>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <Translate attributes={{placeholder: 'enter_your_notes'}} id="notes" defaultValue={this.state.notes} onChange={this.handleChange}  component="textarea" required="required"/>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="paymentButton" style={{margin:"15px auto auto"}}>
                                                <button type="submit"><Translate content="send"/></button>
                                            </div>
                                        </div>
                                    </div>

                    
                                </div>
                            </form>
                        </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SalesInquire