import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import basketIcon from "./component/assest/img/basketIcon.png";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import logo from "./component/assest/img/icon.png";
import pluseImg from "./component/assest/img/pluse.png";
import minasImg from "./component/assest/img/minas.png";

import {BallBeat} from "react-pure-loaders";

let translate = require('counterpart');

class MudiafInquires extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading:false,
            categories: [],
            categoryId: 0,
            products: [],
            enCurrency: "SAR",
            arCurrency: "ريال",

            inquireMeals: (localStorage.getItem("inquireMeals")) ? localStorage.getItem("inquireMeals") : "[]",

            mealId: "",
            mealArName: "",
            mealEnName: "",
            mealArDescription: "",
            mealEnDescription: "",
            mealOriginalPrice: 0,
            mealTotalPrice: 0,
            mealQuantity: 1,
            mealImage: "",

            modal: false,
            disable_button: false,
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };
        axios.get(window.$urlName + 'mdiaf/categories/', {
            headers
        }).then((response) => {
            this.setState({
                categories: response.data.data.mdiafCategories,
                products: response.data.data.mdiafCategories[0].mdiafMeals,
                categoryId: response.data.data.mdiafCategories[0].id,
                loading:false
            })
        }).catch(function (error) {
           // window.location.pathname = "/404"
        });
    }

    modelToggle = () => {
        this.setState({
            modal: !this.state.modal,
            disable_button: false,
        });
    };

    getMeal = (product) => {
        this.setState({
            mealId: product.id,
            mealArName: product.arFullName,
            mealEnName: product.fullName,
            mealArDescription: product.mdiafProduct.arDescription,
            mealEnDescription: product.mdiafProduct.enDescription,
            mealOriginalPrice: product.priceSAR,
            mealTotalPrice: product.priceSAR,
            mealImage: product.image,
            mealQuantity:1,
            loading: false,
        });
    };

    handleAddToBasket = (mealId) =>{
        let meals = [];
        let meal = {
            id: mealId,
            quantity: this.state.mealQuantity,
            mealArName: this.state.mealArName,
            mealEnName: this.state.mealEnName,
            mealArDescription: this.state.mealArDescription,
            mealEnDescription: this.state.mealArDescription,
            mealOriginalPrice: this.state.mealOriginalPrice,
            mealImage: this.state.mealImage,
        };

        if(localStorage.getItem("inquireMeals") !== null){
            meals = JSON.parse(localStorage.getItem("inquireMeals"));
        }

        if(JSON.parse(localStorage.getItem("inquireMeals")) && JSON.parse(localStorage.getItem("inquireMeals")).find(item => mealId === item.id)){
            let arr = JSON.parse(localStorage.getItem("inquireMeals") || "[]");
            let existedLocalStorage = arr.findIndex(item => mealId === item.id);
            arr[existedLocalStorage].quantity += this.state.mealQuantity;
            localStorage.setItem("inquireMeals", JSON.stringify(arr));
        }else{
            console.log(meal);
            meals.push(meal);
            localStorage.setItem("inquireMeals", JSON.stringify(meals));
        }

        this.setState({
            inquireMeals: localStorage.getItem("inquireMeals"),
            modal: !this.state.modal,
        });
    }

    getMealQuantity = (mealId) => {
        let arr = JSON.parse(localStorage.getItem("inquireMeals") || "[]");
        let existedLocalStorage = arr.findIndex(item => mealId === item.id);
        return arr[existedLocalStorage].quantity;
    };

    handleAddQuantity = () => {
        this.setState({
            mealQuantity: this.state.mealQuantity+1,
            mealTotalPrice: (this.state.mealQuantity+1) * this.state.mealOriginalPrice,
        });
    };

    handleSubtractQuantity = () => {
        if(this.state.mealQuantity>1){
            this.setState({
                mealQuantity: this.state.mealQuantity - 1,
                mealTotalPrice: (this.state.mealQuantity - 1) * this.state.mealOriginalPrice,
            });
        }
    };

    getProducts = (id,products) => {
        this.setState({
            products: products,
            categoryId: id,
            loading:false
        })
    };
    
    render() {
        let categories = this.state.categories.map((category) => {
            return (
                <div className={this.state.categoryId === category.id ? "categoryFilter activeCategory" : "categoryFilter" } key={category.id} onClick={() => this.getProducts(category.id,category.mdiafMeals)}>
                    <div className="categoryItem" id={category.id}>
                        <div className="divImg"  style={{backgroundImage: `url(${category.image})`}}></div>
                    </div>
                    <div className="categoryItem" id={category.id}>
                            {translate.getLocale() === 'ar' ? <p>{category.arName}</p> : <p>{category.enName}</p>}
                    </div>
                </div>
            )
        });

        let products = this.state.products.map((product) => {
            return (
                <div className="row mealCard"  key={product.id}>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <div className="branchImg" style={{backgroundImage: `url(${product.image})`}}>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                        <div className="branchSingleCardTitle">
                            {translate.getLocale() === 'ar' ? <h3>{product.arFullName}</h3> : <h3> {product.fullName} </h3>}
                            {translate.getLocale() === 'ar' ? <p>{product.mdiafProduct.arDescription}</p> : <p>{product.mdiafProduct.enDescription}</p>}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="categoryPrice">
                            <button type="button" onClick={() => {
                                this.modelToggle();
                                this.getMeal(product);
                                }}>
                                {(JSON.parse(localStorage.getItem("inquireMeals")) && JSON.parse(localStorage.getItem("inquireMeals")).find(item => product.id === item.id)) ? 
                                <div className="mdiaf-quantity">{this.getMealQuantity(product.id)}</div>
                                :
                                <img src={basketIcon} alt="basketIcon"/>
                            }
                                </button>
                            </div>
                    </div>
                </div>
            )
        });

        return (
            <Fragment>
                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf`}}>
                                <Translate content="mudiaf_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-services`}}>
                                <Translate content="mudiaf_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items mudiaf-items-active">
                            <Link to={{pathname: `/medhyaf-inquires`}}>
                                <Translate content="mudiaf_inquires"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-images`}}>
                                <Translate content="mudiaf_images"/>
                            </Link>
                        </div>
                                  <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-contact`}}>
                                <Translate content="mudiaf_contact"/>
                            </Link>
                        </div>
                    </div>

                    <div className="row personDiv">
                        <div className="col-lg-4 col-md-4">
                            <label><Translate content="you_choose" component="span"/></label>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <label>{JSON.parse(this.state.inquireMeals).length} <Translate content="product" component="span"/></label>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <Link  to={{pathname: `/medhyaf-basket`}}>
                                <button className="bookButton" disabled={!JSON.parse(this.state.inquireMeals || "[]").length}><Translate content="goToBasket"/></button>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                
                    <div className="container">        
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <Translate component="p" className="mudiaf-inquireP" content="inquireParagraph2"/>
                                </div>
                            </div>
                        </div>

                        <div className="loadingSec">
                            <BallBeat
                                color={'#2C2C2C'}
                                loading={this.state.loading}
                            />
                        </div>
                        
                        {categories.length > 0 ?
                            <div className="row categoryDiv">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <p><Translate content="categories" component="span"/> </p>
                                </div>
                            </div> : null}
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 overFlow">
                                {categories}
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 overFlow" style={{position: "relative"}}>
                                {products}
                            </div>
                        </div>
                    </div>

                </div>

                <Modal isOpen={this.state.modal} toggle={this.modelToggle} className={this.props.className}
                       id="productModel">
                    <ModalHeader toggle={this.modelToggle}>
                        <img src={logo} alt="logo" className="logoModel"/>
                    </ModalHeader>
                    <ModalBody className="productModel">
                        <div className="loadingSec">
                            <BallBeat
                                color={'#2C2C2C'}
                                loading={this.state.loading}
                            />
                        </div>

                        <div className="addToBasketBody" id="productInfo">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="productImg"
                                             style={{backgroundImage: `url(${this.state.mealImage})`}}>
                                        </div>
                                        {translate.getLocale() === 'ar' ? <h1>{this.state.mealArName}</h1> :
                                            <h1>{this.state.mealEnName}</h1>}
                                   
                                        {translate.getLocale() === 'ar' ?
                                            <p>{this.state.mealArDescription}</p> :
                                            <p>{this.state.mealEnDescription}</p>}
                                    </div>
                                    <div className="divedLine"></div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="parent">
                                            <div className="child">
                                                <form>
                                                    <div className="foodCheckList">       

                                                        <p className="arialFont"> {this.state.mealTotalPrice} {translate.getLocale() === 'ar' ?
                                                            <span>{this.state.arCurrency}</span> :
                                                            <span>{this.state.enCurrency}</span>}</p>

                                                        <div className="quantitySec add-remove">
                                                            <button type="button" onClick={() => {
                                                                this.handleAddQuantity()
                                                            }} className="plus"><img
                                                                src={pluseImg} alt="plus"/>
                                                            </button>

                                                            <b>{this.state.mealQuantity}</b>

                                                            <button type="button" onClick={() => {
                                                                this.handleSubtractQuantity()
                                                            }} className="min"><img
                                                                src={minasImg} alt="min"/>
                                                            </button>
                                                        </div>

                                                        <button type="button" className="orangeButton" onClick={() => {
                                                            this.handleAddToBasket(this.state.mealId)
                                                        }}><Translate content="add_basket"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default MudiafInquires