import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Swiper from "react-id-swiper";

import {BallBeat} from "react-pure-loaders";
import Sticky from "react-sticky-el";
import moneyIcon from "./component/assest/img/moneyIcon.svg";
import personIcon from "./component/assest/img/personIcon.svg";

let translate = require('counterpart');

class MudiafSingleMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            categoryId: 0,
            products: [],
            menus: [],
            menuId: window.location.href.split('/')[4],
            menuNameEn: "",
            menuNameAr: "",
            phone1: "",
            phone2: "",
            phone3: "",
            phone4: "",
            minPersonsNumber: 0,
            pricePersonSAR: 0,
            loadingSwip: true,
            banners: [],
            loading:false
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };

        axios.get(window.$urlName + 'mdiaf/menus/'+ this.state.menuId, {
            headers
        }).then((response) => {
            this.setState({
                categories: response.data.data.mdiafMenuCategories,
                minPersonsNumber: response.data.data.minPersonsNumber,
                pricePersonSAR: response.data.data.pricePersonSAR,
                menuNameEn: response.data.data.enName,
                menuNameAr: response.data.data.arName,
                products: response.data.data.mdiafMenuCategories[0].mdiafMenuMeals,
                categoryId: response.data.data.mdiafMenuCategories[0].id,
                loading:false
            })
        }).catch(function (error) {
            window.location.pathname = "/404"
        });

        axios.get(window.$urlName + 'mdiaf/menus', {
            headers
        }).then((response) => {
            this.setState({
                menus: response.data.data.mdiafMenus,
                loading:false
            })
        }).catch(function (error) {
            // console.log(error.response)
        });


        axios.get(window.$urlName + 'infos/mdiaf_phones', {
            headers
        }).then((response) => {
            this.setState({
                phone1: response.data.data.mdiafPhone1,
                phone2: response.data.data.mdiafPhone2,
                phone3: response.data.data.mdiafPhone3,
                phone4: response.data.data.mdiafPhone4,
                loading:false
            })
        }).catch(function (error) {
            // console.log(error.response)
        });

        axios.get(window.$urlName + 'banners', {
            params: {
                type: 'mudiaf',
            }
        }).then((response) => {
            this.setState({
                banners: response.data.data,
                loadingSwip: false
            });
        });
    }

    getProducts = (id,products) => {
        this.setState({
            products: products,
            categoryId: id,
            loading:false
        })
    };


    render() {
        const menusSwiper = {
            slidesPerView: 3,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 1,
                },
                320: {
                    slidesPerView: 1,
                }
            },
        };

        const homeParamsSwiper = {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            spaceBetween: 30,
            autoplay: {
                delay: 4000,
            },
        };

        let banners =
            this.state.banners.map((banner, index) => {
                return (
                    <div className="homeSlide" style={{backgroundImage: `url(${banner.image})`}} key={index}></div>
                )
            });

        let others = this.state.menus.map((other) => {
            return (
                <div key={other.id}>
                    <div className="branchSingleCard">
                        <div className="branchImg" style={{backgroundImage: `url(${other.image})`}}>
                        </div>
                        {translate.getLocale() === 'ar' ? <h3>{other.arName}</h3> :
                            <h3>{other.enName}</h3>}
                        <p> <img src={moneyIcon} alt="money"/> {other.pricePersonSAR}
                            {translate.getLocale() === 'ar' ? <span>{this.state.currAr}</span> : <span>{this.state.currEn}</span>} <img src={personIcon} style={{width:"13px"}} alt="person"/> <Translate content="min_persons" component="span"/> {other.minPersonsNumber} <Translate content="person" component="span"/></p>

                        <a href={'/medhyaf-menu/' + other.id}>
                            <button type="button" className="callDerive" style={{background:"#DB095B"}}>
                                <Translate content="openMenu"/>
                            </button>
                        </a>
                    </div>
                </div>
            )
        });

        let categories = this.state.categories.map((category) => {
            return (
                <div className={this.state.categoryId === category.id ? "categoryFilter activeCategory" : "categoryFilter" } key={category.id} onClick={() => this.getProducts(category.id,category.mdiafMenuMeals)}>
                    <div className="categoryItem" id={category.id}>
                        <div className="divImg"  style={{backgroundImage: `url(${category.image})`}}></div>
                    </div>
                    <div className="categoryItem" id={category.id}>
                            {translate.getLocale() === 'ar' ? <p>{category.arName}</p> : <p>{category.enName}</p>}
                    </div>
                </div>
            )
        });

        let products = this.state.products.map((product) => {
            return (
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" key={product.id}>
                    <div className="branchSingleCard" style={{margin:"auto auto 20px",height:"auto"}}>
                        <div className="branchImg" style={{backgroundImage: `url(${product.image})`}}>
                        </div>
                        <div className="branchSingleCardTitle">
                            {translate.getLocale() === 'ar' ? <h3><label><Translate content="number"/> ({product.quantity}) </label> - {product.arFullName}</h3> :
                                <h3><label><Translate content="number"/> ({product.quantity}) </label> - {product.fullName}</h3>}

                            {translate.getLocale() === 'ar' ? <p>{product.mdiafProduct.arDescription}</p> :
                                <p>{product.mdiafProduct.enDescription}</p>}
                        </div>
                    </div>
                </div>
            )
        });
        return (
            <Fragment>
                <div className="homeSwiper">

                    {this.state.banners.length !== 0 ? (

                        <Swiper {...homeParamsSwiper}>
                            {banners}
                        </Swiper>
                    ) : <div className="loadingSec" style={{marginTop: "200px", marginBottom: "200px"}}>
                        <BallBeat
                            color={'white'}
                            loading={this.state.loadingSwip}
                        />
                    </div>}

                </div>

                <div className="container-fluid menusDiv">
                    <div className="loadingSec">

                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>

                    <Sticky stickyClassName="stickyFilter">
                        <div className="mudiaf-bar">
                            <div className="mudiaf-items mudiaf-items-active">
                                <Link to={{pathname: `/medhyaf`}}>
                                    <Translate content="mudiaf_menus"/>
                                </Link>
                            </div>
                            <div className="mudiaf-items">
                                <Link to={{pathname: `/medhyaf-services`}}>
                                    <Translate content="mudiaf_services"/>
                                </Link>
                            </div>                        
                            <div className="mudiaf-items">
                                <Link to={{pathname: `/medhyaf-inquires`}}>
                                    <Translate content="mudiaf_inquires"/>
                                </Link>
                            </div>
                            <div className="mudiaf-items">
                                <Link to={{pathname: `/medhyaf-images`}}>
                                    <Translate content="mudiaf_images"/>
                                </Link>
                            </div>
                            <div className="mudiaf-items">
                                <Link to={{pathname: `/medhyaf-contact`}}>
                                    <Translate content="mudiaf_contact"/>
                                </Link>
                            </div>
                        </div>
                        
                        <div className="row personDiv">
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                {translate.getLocale() === 'ar' ? <p>{this.state.menuNameAr}</p> : <p>{this.state.menuNameEn}</p>}
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <label><Translate content="min_persons" component="span"/> {this.state.minPersonsNumber} <Translate content="person" component="span"/></label>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <Link  to={{pathname: `/medhyaf-booking/${this.state.menuId}`}}>
                                    <button className="bookButton"><Translate content="book_menu"/> <label>{this.state.pricePersonSAR} <Translate content="SAR"/></label> </button>
                                </Link>
                            </div>
                        </div>
                    </Sticky>
                </div>

                <div className="container-fluid branchesSec">
                    <div className="container">
                        {categories.length > 0 ?
                            <div className="row categoryDiv">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <p><Translate content="categories" component="span"/> </p>
                                </div>
                            </div> : null}
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 overFlow">
                                {categories}
                            </div>
                            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 overFlow" style={{position: "relative"}}>
                                <div className="row">
                                    {products}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid branchesSec anotherMenus">
                    <Translate content="anotherMenus" component="h1"/>
                    <div className="row menusBar">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {this.state.menus.length !== 0 ? (
                                <Swiper {...menusSwiper} >
                                    {others}
                                </Swiper>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default MudiafSingleMenu