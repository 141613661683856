import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from 'react-router-dom';
import awafiReg from "./component/assest/img/awafiReg.jpg";

class AwafiSA extends Component {
    render(){
        return(
            <Fragment>
                <div className="container">
                    <div className="termsBody">
                        <img src={awafiReg} alt="vat" width="100%"/>
                        <Link style={{marginTop:"30px"}} to="/awafi" id="sign-in-button" className="orangeButton">
                                    <Translate content="updateNow"/></Link>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default AwafiSA
