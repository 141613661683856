import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import moneyIcon from "./component/assest/img/moneyIcon.svg";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import SalesBanner from "./salesBanner";

import {BallBeat} from "react-pure-loaders";

let translate = require('counterpart');

class SalesServices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            services: [],
            currAr: "ريال",
            currEn: "SAR",
            loading:false,
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };
        axios.get(window.$urlName + 'sales/services', {
            headers
        }).then((response) => {
            this.setState({
                services: response.data.data.salesServices,
                loading:false
            })
        }).catch(function (error) {
             //console.log(error.response)
        });
    }

    render() {
        let services = this.state.services.map((service) => {
            return (

                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={service.id}>
                    <div className="branchSingleCard">

                        <div className="branchImg" style={{backgroundImage: `url(${service.featuredImg})`}}>
                        </div>
                        {translate.getLocale() === 'ar' ? <h3>{service.arName}</h3> :
                            <h3>{service.enName}</h3>}
                        <p> <img src={moneyIcon} alt="money"/> {service.price}
                            {translate.getLocale() === 'ar' ? <span>{this.state.currAr}</span> : <span>{this.state.currEn}</span>}</p>
                        <p>{translate.getLocale() === 'ar' ? <span>{service.arDescription}</span> : <span>{service.enDescription}</span>}</p>
                        <a href="tel:0502299281">
                            <button type="button" className="callDerive" style={{background:"#DB095B"}}>
                                <Translate content="call_agent"/>
                            </button>   
                        </a>
                    </div>
                </div>
            )
        });
        return (
            <Fragment>
                <SalesBanner></SalesBanner>

                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies`}}>
                                <Translate content="companies_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items companies-items mudiaf-items-active">
                            <Link to={{pathname: `/companies-services`}}>
                                <Translate content="companies_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-clients`}}>
                                <Translate content="companies_clients"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-contact`}}>
                                <Translate content="contact_us"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <div className="container">
                        <div className="row">
                            {services.length>0 ? services : 
                                <Translate content="noResults" component="p" className="no_results"/>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SalesServices