import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import SalesBanner from "./salesBanner";

class MudiafContact extends Component {

    render() {
        return (
            <Fragment>
                <SalesBanner></SalesBanner>

                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies`}}>
                                <Translate content="companies_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-services`}}>
                                <Translate content="companies_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-clients`}}>
                                <Translate content="companies_clients"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items companies-items mudiaf-items-active">
                            <Link to={{pathname: `/companies-contact`}}>
                                <Translate content="contact_us"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
          
                    <div className="container mdiaf-contact">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Translate component="p" className="contactP" content="contact_us"/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                <a href="tel:0502299281">0502299281</a>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                                <a href="tel:920000144">920000144</a>
                            </div>
                        </div>         
               
                    </div>
               </div>
            </Fragment>
        )
    }
}

export default MudiafContact