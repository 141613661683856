export default {

    'lang': 'English',
    'ar': 'العربية',
    'signUp_signIn' : 'تسجيل الدخول / التسجيل',
    'home' : 'الرئيسية',
    'about_place' : 'عن المكان',
    'media_center' : 'المركز الإعلامي',
    'branches' : 'فروعنا',
    'menu' : 'المنيو',
    'contact_us' : 'تواصل معنا',
    'followUs' : 'تابعنا علي مواقع التواصل الإجتماعي',
    'awafi' : 'عوافي',
    'food_menu' : 'قائمة الطعام',
    'topCustomers' : 'كبار العملاء',
    'hospitable' : 'مضياف للحفلات',
    'country' : 'البلد',
    'bahrain' : 'البحرين',
    'saudi' : 'السعودية',
    'footer_prg' : 'وفرنا لك تطبيق عشان نخدمك ونقدم لك ألذ الطعام' ,
    'signUp' : 'التسجيل' ,
    'name' : 'الأسم' ,
    'phone' : 'عطنا رقم جوالك' ,
    'tel' : 'الجوال' ,
    'password' : 'حط كلمة المرور' ,
    'confirm_password' : 'تأكيد كلمة المرور' ,
    'have_account' : 'لديك حساب ؟' ,
    'signIn' : 'تسجيل الدخول' ,
    'dont_have_account' : 'ما عندك حساب ؟' ,
    'forget_password' : 'نسيت كلمة المرور ؟' ,
    'signIn_register' : 'سجل دخولك' ,
    'enter_code' : 'إدخل الرمزالتأكيد' ,
    'confirm' : 'تأكيد' ,
    'resend' : 'إعادة الإرسال' ,
    'didnt_Arrive' : 'لم تصل لك الرسالة ؟' ,
    'change_phone' : 'تغير رقم الجوال',
    'enter_phone' : 'إدخل رقم الجوال' ,
    'myPage' : 'صفحتك' ,
    'basket' : 'سلتك' ,
    'price' : 'السعر' ,
    'SAR' : 'ر.س' ,
    'total' : ' الإجمالي' ,
    'continue' : 'إستمرار' ,
    'my_profile' : 'بياناتك' ,
    'my_wallet' : 'محفظتك' ,
    'chargeWallet' : 'إشحن محفظتك' ,
    'enterBalance' : 'ادخل الرصيد' ,
    'my_orders' : 'طلباتك' ,
    'points_credit' : 'رصيد النقاط' ,
    'save' : 'حفظ' ,
    'points' : 'نقاط' ,
    'replace_points' : 'تبديل النقاط' ,
    'birth_date' : 'تاريخ ميلادك' ,
    'choose_city' : 'إختر المدينة' ,
    'male' : 'ذكر' ,
    'female' : 'أنثى' ,
    'gender' : 'النوع' ,
    'approved_conditions' : 'موافق على الشروط و الأحكام' ,
    'about_hospitable' : 'هي شركة رائدة في مجال الضيافة والتموين للقطاعين الحكومي والخاص ولديها خبرة واسعة في إعداد أفضل الأطعمة في إطار متطلبات السلامة الغذائية العالمية. الخدمات هي توفير أطراف خارجية من جميع الأنواع في موقع العميل من بوفيه الإفطار والوجبات الخفيفة في الاجتماعات وورش العمل والترفيه للمواقع الخاصة والحكومية والضيوف الرحمن للحج والعمرة وتنتهي مع بوفيه للغداء والعشاء ، والمنتجات في قوائم بوفيه مفتوح بين المأكولات الغربية والشرقية من المقبلات والرئيسية الأطباق والحلويات. نتطلع إلى أن نكون الشركة الرائدة في مجال الضيافة مع الخدمة المتميزة والموظفين المحترفين ومجموعة واسعة من المنتجات وصالات العرض عالية الجودة للحصول على أفضل قيمة ونتطلع إلى أن نكون أفضل شركة أغذية في العالم. تقديم الطعام والمناسبات في منطقة الخليج العربي.' ,
    'received_order' :'إستلم طلبك من الفرع',
    'car_order' :'إستلم طلبك في السيارة',
    'carDetails' :'تفاصيل السيارة',
    "carModel":"موديل السيارة",
    "carColor":"لون السيارة",
    "carNumber":"رقم السيارة",
    "carNotes":"ملاحظات خاصة بالسيارة",
    'you_choose' :'انت اخترت',
    'change_branch' :'تغير الفرع',
    'meet' :'لحوم',
    'fastDelivery' :'توصيل سريع',
    'ordinary_Delivery' :'توصيل عادي',
    'enter_promo' :'حط كود الخصم',
    'total_price' :' المجموع الكلي',
    'additionals' :'إختر الإضافات',
    'add_basket' :'إضافة السلة',
    'payment' :'اختر طريقة الدفع',
    'wallet_payment' :'دفع عن طريق المحفظة',
    'arrivalPayment' :'دفع عند الإستلام',
    'creditCardPayment' :'بطاقة الائتمان',
    'back_to_list' :'رجوع للقائمة',
    'estimate_time' :'الوقت المستغرق',
    'delivery' :'مصاريف التوصيل',
    'total_cost' :' المجموع الكلي',
    'order_received' :'تاريخ الطلب',
    'order_time' :'وقت الطلب',
    'order_state' :'وضع الطلب',
    'order_accepted' :'أستلمنا طلبك',
    'order_InProgress' :'طلبك يتجهز',
    'done_order' :'الطلب وصل',
    'cancel_request' :'كنسل الطلب',
    'order_with_driver' :'مسافة الطريق',
    'delivered' :'الطلب وصل',
    'call_derive' :'أتصل',
    'delivery_type' :' نوع التسليم',
    'share_them' :'حملة شاركهم',
    'from_branch' :'تستلم من الفرع',
    'from_home' :'نجيك البيت',
    'change_address' :' تغيير العنوان',
    'address' :' العنوان',
    'closet_branch' :'اقرب رومانسية لك',
    'extraa' :'الإضافات',
    'copy_write_parg' :'حقوق الملكية محفوظة لشركة الرومانسية',
    'bestTry' :'كيف تبي طلبك',
    'foodList' :'قائمة الطعام',
    'smokes' :'المشويات',
    'see_list' :'شاهد القائمة',
    'desserts' :'الحلويات',
    'meats' :'اللحوم',
    'chicken' :'الدجاج',
    'soup' :'أطباق جانبية',
    'drinks' :'المشروبات',
    'choose_location' :'إختر عنوانك',
    'update' :'تعديل',
    'hi' :'مرحبا',
    'choose_country' :'إختر البلد',
    'choose_type' :'طريقة الطلب',
    'choose_category' :'إختر الفئة',
    'meal_quantity' :'عدد الوجبات',
    'choose' :'إختر',
    'new_address' :'عنوان جديد',
    'you_have_ordered' :'سلة المشتريات :',
    'nothing' :'ماعندك طلبات.',
    'delete' :'حذف',
    'sec' :'ثانية',
    'branch_name' :'اسم الفرع',
    'branch_phone' :'رقم الفرع',
    'pre_orders' :'الطلبات المنتهية',
    'next_orders' :'الطلبات الحالية',
    'choose_branch' :'إختر الفرع',
    'title' :'سمي عنوانك',
    'extra' :'الزيادات',
    'change_points' :'تبديل النقاط',
    'visit_branch' :'لتغير النقاط الرجاء التوجهة',
    'close_branch' :' لأقرب فرع',
    'enter_your_notes' : 'إدخل ملاحظاتك',
    'delivery_choice' : 'نوع الطلب',
    'now' : 'اللحين',
    'later' : 'بعدين',
    'delete_modal' : 'هل انت متأكد من حذف الطلب ؟',
    'cancel' : 'إلغاء',
    'quantity' : 'الكمية',
    'add_more_item' : 'أضافة المزيد',
    'email' : 'البريد الإلكتروني',
    'send' : 'إرسال',
    'upload' : 'إرسال',
    'message' : 'رسالتك',
    'city' : 'المدينة',
    'change_country' : 'تغير البلد',
    'name_required' : 'عطنا أسمك',
    'phone_required' : 'ولا عليك آمر عطنا رقم جوالك',
    'password_required' : 'لا تنسى كلمة المرور',
    'phone_not_valid' : 'تأكد من رقمك',
    'at_least' : 'رمزك لازم يكون علي الاقل سته أحرف',
    'invalid_email' : 'البريد الإلكتروني غير مصرح به',
    'email_required' : 'برجاء إدخال البريد الإلكتروني',
    'message_required' : 'برجاء إدخال رسالتك',
    'country_required' : 'برجاء إدخال البلد',
    'confirm_required' : 'برجاء إعادة أدخال كلمة السر',
    'password_must_match' : 'ترى كلمة المرور مو نفس الشئ',
    'calories' : 'السعرات الحرارية : ',
    'terms' : 'الشروط و الأحكام',
    'logout' : 'خروج',
    'logout_modal' : 'هل تريد تسجيل خروج ؟',
    'order_type' : 'نوع الطلب',
    'clear_basket' : 'إذا غيرت طريقة الطلب بتفضى سلتك !',
    'change_delivery' : 'إذا غيرت طريقة الطلب بتفضى سلتك !',
    'disc_mount' : 'خصم',
    'rate_order' : 'قيم طلبك',
    'back' : 'رجوع',
    'wait_orders' : 'الطلبات منتظرة الدفع',
    'details' : 'التفاصيل',
    'size' : 'الحجم',
    'success_payment' : 'وصلنا المبلغ',
    'success_awafi_signup' : 'لقد قمت بالتسجيل في برنامج عوافي بنجاح',
    'notSuccess_payment' : 'جرب مره ثانية',
    'track_order' : 'متابعة طلبك',
    'pay_again' : 'الدفع مره اخري',
    'calorie' : 'السعرات',
    'id' : 'رقم',
    'additions' : 'الأضافات',
    'code' : 'رقم الكود',
    'extras' : 'الزيادات',
    'call_branch' : 'إتصل علينا',
    'call_driver' : 'إتصل بالسائق',
    'no_products' : 'لا يوجد منتجات متوفرة',
    'no_orders' : 'ماعندك طلبات',
    'total_price_after_disc' : ' المجموع بعد الخصم',
    'discount' : 'الخصم',
    'delivery_price' : 'رسوم التوصيل',
    'page_not_found' : 'لم يتم العثور',
    'min' : 'دقيقة',
    'salad' : 'المقبلات',
    'spanish' : 'بوكسبرز',
    'log_out' : 'تسجيل خروج',
    'nothing_basket' : 'سلتك فاضيه',
    'product_must_be_faild' : 'يجب إضافة منتجات لسلتك',
    'loyalty' : 'عوافي',
    'no_branches' : 'لا يوجد فروع متوفرة',
    'our_branches' : 'فروعنا',
    'order_id' : 'رقم الطلب',
    'sorry' : 'تنبيه',
    'start_alromansiah' : 'أبدا رومانسيتك',
    'choose_Nationality' : 'إختر الجنسية',
    'change_Delivery' : 'تغير طريقة الطلب',
    'open_now' : 'مفتوح الأن',
    'closed' : 'مغلق الأن',
    'without_delivery' : ' غير شامل مصاريف التوصيل',
    'your_rate' : 'تقييمك',
    'change_password' : 'تغيير كلمة المرور',
    'notes' : 'الملاحظات',
    'meals' : 'الوجبات',
    'paymentMethod' : 'طريقة الدفع',
    'order_details' : 'تفاصيل الطلب',
    'choose_address' : 'إختر عنوان',
    'no_address' : 'لا يوجد عنواين',
    'terms_must_checked' : 'يجب عليك الموافقة علي الشروط والأحكام',
    'agree_terms' : 'وافقت على',
    'terms_conditions' : 'الشروط و الأحكام',
    'tax' : ' الضريبة',
    'order_scan' : ' فحص الطلب',
    'address_name' : 'إسم العنوان',
    'you_win' : 'مبروك كسبت',
    'busy_branch' : 'الفرع مشغول' ,
    'cancelReason1' : 'وقت التوصيل غير مناسب',
    'cancelReason2' : 'أرغب في تغيير الطلب',
    'cancelReason3' : 'لا أستطيع استلام الطلب',
    'changePaymentMethod' : 'تغيير طريقة الدفع',
    'recommendedMeals' : 'وجبات مقترحة',
    'redirectMessage' : 'جاري التوجيه الي صفحة الدفع',

    //Mudiaf Translations
    'welcome_mudiaf' : 'مرحبا بك في مضياف',
    'mudiaf_orders' : 'طلبات مضياف',
    'mudiaf_menus' : 'القوائم الرئيسية',
    'mudiaf_services' : 'الخدمات الإضافية',
    'mudiaf_inquires' : 'حفلتك علي ذوقك',
    'mudiaf_images' : 'صور و مشاركات',
    'mudiaf_contact' : 'تواصل معنا',
    'min_persons' : 'حد أدني',
    'num_persons' : 'عدد الأفراد',
    'person' : 'فرد',
    'book_menu' : 'أحجز القائمة',
    'waitingPayment' : 'بانتظار الدفع',
    'inProgress' : 'جاري التجهيز',
    'confirmed' : 'تم الموافقة',
    'done' : 'تم تنفيذ الطلب',
    'youChoose' : 'انت اخترت',
    'openMenu' : 'شاهد القائمة',
    'enterAddress' : 'إدخل عنوانك',
    'categories' : 'التصنيفات',
    'personPrice' : 'سعر القائمة',
    'number' : 'عدد',
    'chooseDate' : 'إختر التاريخ',
    'anotherMenus' : 'قوائم أخري',
    'forMore' : 'لمزيد من الاستفسارات, اتصل بنا',
    'noResults' : 'لا يوجد نتائج حالية',
    'sharePhotos' : 'شاركنا بالصور',
    'sharePhotosaragraph' : 'شاركنا بتجربتك مع مضياف الرومانسية',
    'inquireParagraph' : 'أرسل لنا تفاصيل طلبك وكون حفلتك الان',
    'inquireParagraph2' : 'أختر ألذ الوجبات وكون حفلتك الان',
    'inquireSuccess' : 'إستلمنا طلبك, سيتم التواصل معك من خلال مندوبينا',
    'imageSuccess' : 'شكرا لك , مشاركتك تحت المراجعة',
    'selectPhoto' : 'إختار صورة',
    'backToMenu' : 'الرجوع إلي القائمة',
    'by' : 'بواسطة',
    'goToBasket' : 'الذهاب إلي السلة',
    'downloadAppNow' : 'حمل التطبيق الان',
    'product' : 'منتجات',
    'location' : 'الموقع علي الخريطة',
    'callBranch' : 'إتصل بالفرع',


    'companiesSubscriptions' : 'مبيعات الشركات',
    'companies_menus' : 'قوائم الشركات',
    'companies_services' : 'خدمات الشركات',
    'companies_clients' : 'عملاءنا',
    'about_companies' : ' يقوم قسم المبيعات الخارجية بتقديم الخدمة لموظفي الشركات والمصانع بأسعار منافسة،وكذلك يتعهد القسم بإقامة الحفلات الموسمية وتقديم المساعدات للجمعيات الخيرية والتعاقد مع الفنادق والشركات والجمعيات وكذلك يقوم بخدمة العملاء في المناسبات والفعاليات بواسطة المطعم المتنقل (فودترك)' ,
    'person_cost' : 'تكلفة الفرد',
    'call_agent' : 'إتصل بمندوبنا',
    'enterCompanyName' : 'ادخل اسم الشركة',

    
    'commercialRegister' : 'السجل التجاري',
    'taxNumber' : 'الرقم الضريبي',
    'updateNow' : 'حدث بياناتك الأن',

    

};
