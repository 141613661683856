import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import Swiper from "react-id-swiper";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import logo from "./component/assest/img/icon.png";
import {BallBeat} from "react-pure-loaders";
import axios from "axios";
import {addToCart, RESETAPP} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";
import noProduct from "./component/assest/img/noProduct.svg";


let translate = require('counterpart');

class RecommendedProducts extends Component {
    constructor (props) {
        super(props);
        this.state = {
            searchTerm: '',
            branchId: parseInt(localStorage.getItem("branchId")),
            branchcategories: [],
            showActionFilterList: false,
            branchModal: false,
            categoryModal: false,
            selectedOption: "",
            selectedOptionAddition: "",
            arName: "",
            enName: "",
            modal: false,
            sizes: [],
            sizeId: "",
            sizeAr: "",
            sizeEn: "",
            productId: null,
            additionId: null,
            sizePrice: 0,
            totalAddPrice: null,
            additionsPrice: [],
            totalPrice: null,
            quantity: 1,
            newQuantity: this.quantity,
            max: 5,
            min: 1,
            key: '1',
            selectedProducts: [],
            selectedAdditionals: [],
            selectedExtras: [],
            additionals: [],
            extras: [],
            singleProduct: '',
            loading: true,
            noOrderAr: '',
            noOrderEn: '',
            redirect: false,
            deliveryTypeAr: "",
            deliveryTypeEn: "",
            disable_button: false,
            calories: "0",
            requiredAdd: null,
            clearBasketModel: false,
            addArName: "",
            addEnName: "",
            extrasArName: [],
            extrasEnName: [],
            checked: false,
        };
    }

    handleOptionChange = (id, additionals, e, isAdditionalRequired, sizePrice, calories, sizeAr, sizeEn) => {
        if (isAdditionalRequired === true) {
            this.setState({
                requiredAdd: true,
                calories: 0,
                sizePrice: 0
            });
            if (additionals.length !== 0) {
                document.getElementById("additionals").style.display = "block";
                this.setState({
                    selectedAdditionals: [],
                    disable_button: false,
                    selectedOptionAddition: ""
                })

            }
        } else {
            this.setState({
                sizePrice: parseFloat(sizePrice),
                calories: calories,
                disable_button: true,
                addArName: "-",
                addEnName: "-",
                selectedAdditionals:[]

            })

            if (additionals.length !== 0) {
                document.getElementById("additionals").style.display = "block";
                this.setState({
                    selectedAdditionals: [],
                    disable_button: true,
                    selectedOptionAddition: ""
                })

            }
        }

        this.setState({
            selectedOption: e.target.value,
            sizeId: id,
            sizeAr: sizeAr,
            sizeEn: sizeEn,
            additionals: additionals,
        });

    };

    handleOptionChangeAddition = (id, price, calories, arName, enName, e) => {
        this.setState({
            selectedOptionAddition: e.target.value,
            additionId: id,
            sizePrice: parseFloat(price),
            calories: calories,


        });

        if (e.target.value !== null) {
            this.setState({
                disable_button: true,
            })
        }
        let selectedAdditionals = [];
        selectedAdditionals.push(parseInt(id));
        this.setState({
            selectedAdditionals: selectedAdditionals,
            addArName: arName,
            addEnName: enName,


        });

    };

    modelToggle = () => {
        this.setState(() => ({
            modal: !this.state.modal,
            selectedOption: "",
            selectedOptionAddition: "",
            selectedExtras: [],
            extrasArName: [],
            extrasEnName: [],
            additionals: [],
            sizeId: "",
            disable_button: false,
            loading: true,
            calories: "0",
            sizePrice: 0,
            selectedAdditionals: [],
        }));


    };

    getProduct = (id, arName, enName) => {

        axios.get(window.$urlName + 'products/' + id, {
            params: {
                branchId: this.state.branchId,
            }
        }).then((response) => {

            this.setState({
                singleProduct: response.data.data,
                sizes: response.data.data.sizes,
                // additionals: response.data.data.additionals,
                extras: response.data.data.extras,
                productId: id,
                arName: arName,
                enName: enName,
                loading: false,

            });
            document.getElementById("productInfo").style.display = "block";
            if (response.data.data.extras.length === 0) {
                document.getElementById("extras").style.display = "none"
            }
        });


    };

    handleClick = (id) => {
        let singleProductOrder = {
            id: id,
            arName: this.state.arName,
            enName: this.state.enName,
            price: this.state.sizePrice,
            sizeId: parseInt(this.state.sizeId),
            sizeAr: this.state.sizeAr,
            sizeEn: this.state.sizeEn,
            addArName: this.state.addArName,
            addEnName: this.state.addEnName,
            extras: this.state.selectedExtras,
            extrasArName: this.state.extrasArName,
            extrasEnName: this.state.extrasEnName,
            sortedExtras: this.state.selectedExtras.sort().toString(),
            additionals: this.state.selectedAdditionals,
            calories: this.state.calories
        };
        let {selectedProducts} = this.state;
        localStorage.setItem("item", JSON.stringify(singleProductOrder));
        this.setState({
            selectedProducts: selectedProducts,
            modal: !this.state.modal,

        });

        this.props.addToCart(id, parseInt(this.state.sizeId), this.state.selectedAdditionals, this.state.selectedExtras.sort().toString());

    };

    onToggle(index, e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
        let newItems = this.state.extras.slice();
        newItems[index].checked = !newItems[index].checked;
        let item = newItems.filter(item => item.checked);

        let selectedID = item.map((selected) => parseInt(selected.id));
        let selectedArName = item.map((selected) => selected.arName + " ");
        let selectedEnName = item.map((selected) => selected.enName + " ");

        this.setState({
            selectedExtras: selectedID,
            extrasArName: selectedArName,
            extrasEnName: selectedEnName
        });

    }

    getextras = () => {
        return (
            this.state.extras.map((extra, i) => {
                return (
                    <div className="col-lg-6 col-md-6 rightText" key={i}>
                        <input type="checkBox" value={extra.id} name={extra.enName} id={extra.enName}
                               onChange={this.onToggle.bind(this, i)}
                        />
                        {translate.getLocale() === 'ar' ? <label htmlFor={extra.enName}>{extra.arName}</label> :
                            <label htmlFor={extra.enName}>{extra.enName}</label>}
                        <span>{extra.price}</span>
                    </div>
                )

            })
        )
    };

    getadditionals = () => {
        return (
            this.state.additionals.map((addition) => {
                return (
                    <div className="col-lg-6 col-md-6 rightText" key={addition.id}>

                        <div className="radio">
                            <input type="radio" value={addition.enName} id={addition.enName}
                                   checked={this.state.selectedOptionAddition === addition.enName}
                                   onChange={(e) => this.handleOptionChangeAddition(addition.id, addition.price, addition.calories, addition.arName, addition.enName, e)}
                                   required/>

                            {translate.getLocale() === 'ar' ?
                                <label htmlFor={addition.enName}>{addition.arName}</label> :
                                <label htmlFor={addition.enName}>{addition.enName}</label>}
                            {/*<span>{addition.price}</span>*/}

                        </div>
                    </div>
                )

            })
        )
    };

    getSizes = () => {
        return (
            this.state.sizes.map((size) => {
                return (
                    <div className="row" key={size.id}>
                        <div className="col-lg-6 col-md-6 rightText">
                            <div className="servicesList">
                                <div className="radio">
                                    <input type="radio" value={size.enName} id={size.enName}
                                           checked={this.state.selectedOption === size.enName}

                                           onChange={(e) => this.handleOptionChange(size.id, size.additionals, e, size.isAdditionalRequired, size.price, size.calories, size.arName, size.enName)}
                                           required/>

                                    {translate.getLocale() === 'ar' ?
                                        <label htmlFor={size.enName}>{size.arName}</label> :
                                        <label htmlFor={size.enName}>{size.enName}</label>}
                                </div>
                            </div>
                        </div>
                    </div>
                )

            })
        )
    }

    render () {

        const { products } = this.props;

        let recommendedProducts = products.map((recommendedProduct) => {
            return (
                <div key={recommendedProduct.id}>
                    <div className="branchSingleCard">
                        <div className="branchImg" style={{backgroundImage: `url(${recommendedProduct.image})`}}>
                        </div>
                        {translate.getLocale() === 'ar' ? <h3>{recommendedProduct.arName}</h3> :
                            <h3>{recommendedProduct.enName}</h3>}

                        {translate.getLocale() === 'ar' ?  <p>{recommendedProduct.arDescription} </p> : <p> {recommendedProduct.enDescription}</p> }

                        <button onClick={() => {
                            this.modelToggle();
                            this.getProduct(recommendedProduct.id, recommendedProduct.arName, recommendedProduct.enName);
                        }} type="button" className="callDerive" style={{background:"#DB095B"}}>
                            <Translate content="add_basket"/>
                        </button>
                    </div>
                </div>
            )
        });


        const recommendedSwiper = {
            slidesPerView: 3,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 1,
                },
                320: {
                    slidesPerView: 1,
                }
            },
        };

        return (
            <Fragment>
                <div className="recommendedProducts">
                    <Translate content="recommendedMeals"  component="p" className="categoryTitle"/>
                    {products.length ?(
                        <Swiper {...recommendedSwiper} >
                            {recommendedProducts}
                        </Swiper>
                    ) :(
                        <div className="noProductSec">
                            <img src={noProduct} alt="no_product"/>
                            <Translate content="no_products" component="p"/>
                        </div>
                    ) }
                </div>


                <Modal isOpen={this.state.modal} toggle={this.modelToggle} className={this.props.className}
                       id="productModel">
                    <ModalHeader toggle={this.modelToggle}>
                        <img src={logo} alt="logo" className="logoModel"/>
                    </ModalHeader>
                    <ModalBody className="productModel">
                        <div className="loadingSec">
                            <BallBeat
                                color={'#2C2C2C'}
                                loading={this.state.loading}
                            />
                        </div>

                        <div className="addToBasketBody" id="productInfo" style={{display: "none"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="productImg"
                                             style={{backgroundImage: `url(${this.state.singleProduct.image})`}}>
                                        </div>
                                        {translate.getLocale() === 'ar' ? <h1>{this.state.singleProduct.arName}</h1> :
                                            <h1>{this.state.singleProduct.enName}</h1>}
                                        {translate.getLocale() === 'ar' ?
                                            <p>{this.state.singleProduct.arDescription}</p> :
                                            <p>{this.state.singleProduct.enDescription}</p>}
                                        <div className="calories">
                                            <Translate content="calories"/>
                                            <span className="arialFont">{this.state.calories}</span>
                                        </div>
                                        <div className="divLine"></div>
                                        <div className="foodCheckList">
                                            {this.getSizes()}
                                        </div>
                                    </div>
                                    <div className="divedLine"></div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="parent">
                                            <div className="child">
                                                <form>
                                                    <div className="foodCheckList">
                                                        <div id="additionals" style={{display: "none"}}>
                                                            <Translate content="additionals" component="h1"
                                                                       className="extra"/>
                                                            <div className="row">
                                                                {this.getadditionals()}
                                                            </div>
                                                            <hr/>

                                                        </div>
                                                        <div id="extras">
                                                            <Translate content="extra" component="h1"
                                                                       className="extra"/>
                                                            <div className="row">
                                                                {this.getextras()}
                                                            </div>
                                                            <hr/>

                                                        </div>
                                                        <Translate content="price" component="h1"
                                                                   className="extra"/>
                                                        <p className="arialFont"> {this.state.sizePrice} {translate.getLocale() === 'ar' ?
                                                            <span>{localStorage.getItem("arCurrency")}</span> :
                                                            <span>{localStorage.getItem("enCurrency")}</span>}</p>

                                                        <button type="button" disabled={!this.state.disable_button}
                                                                className="orangeButton" onClick={() => {
                                                            this.handleClick(this.state.singleProduct.id)
                                                        }}><Translate content="add_basket"/>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>

        )
    }

}
const mapDispatchToProps = (dispatch) => {

    return {
        addToCart: (id, sizeId, additionals, sortedExtras) => {
            dispatch(addToCart(id, sizeId, additionals, sortedExtras))
        },
        RESETAPP: () => {
            dispatch(RESETAPP())
        },

    }
};

export default connect(null, mapDispatchToProps)(RecommendedProducts)
