import React, {Component, Fragment} from "react"
import Translate from "react-translate-component";
import Swiper from 'react-id-swiper';
import menu1 from './component/assest/img/menu1.jpg';
import menu2 from './component/assest/img/menu2.jpg';
import menu3 from './component/assest/img/menu3.jpg';
import menu4 from './component/assest/img/menu4.jpg';
import menu5 from './component/assest/img/menu5.jpg';
import menu6 from './component/assest/img/menu6.jpg';
import menu7 from './component/assest/img/menu7.jpg';
import menu8 from './component/assest/img/menu8.jpg';
import menu9 from './component/assest/img/menu9.jpg';
import menu10 from './component/assest/img/menu10.jpg';
import menu11 from './component/assest/img/menu11.jpg';
import menu12 from './component/assest/img/menu12.jpg';
import menu13 from './component/assest/img/menu13.jpg';
import menu14 from './component/assest/img/menu14.jpg';
import menu15 from './component/assest/img/menu15.jpg';
import menu16 from './component/assest/img/menu16.jpg';
import menu17 from './component/assest/img/menu17.jpg';
import menu18 from './component/assest/img/menu18.jpg';
import menu19 from './component/assest/img/menu19.jpg';
import menu20 from './component/assest/img/menu20.jpg';
import menu21 from './component/assest/img/menu21.jpg';
import menu22 from './component/assest/img/menu22.jpg';
import './component/assest/css/swiper.min.css';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const images = [
    menu1,
    menu2,
    menu3,
    menu4,
    menu5,
    menu6,
    menu7,
    menu8,
    menu9,
    menu10,
    menu11,
    menu12,
    menu13,
    menu14,
    menu15,
    menu16,
    menu17,
    menu18,
    menu19,
    menu20,
    menu21,
    menu22,

];
let translate = require('counterpart');

class OldMudiaf extends Component {

    constructor(props) {
        super(props)
        this.state = {
            contactModal: false,
            name: '',
            phone: '',
            email: '',
            msg: '',
            isOpen:false,
            photoIndex: 0,
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
        }
    }
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    contactTypeModel = () => {
        this.setState(prevState => ({
            contactModal: !prevState.contactModal,

        }));

    };
    handelChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    render() {
        const { photoIndex, isOpen } = this.state;

        const params = {
            effect: 'coverflow',
            centeredSlides: true,
            slidesPerView: '2',
            loop: true,
            // autoplay: {
            //     delay: 3000,
            // },
            coverflow: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
        };
        let phoneRegEx = /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$/;

        return (
            <Fragment>
                <div className="container-fluid hospitableCover">
                </div>
                <div className="container-fluid hospitableBody">
                    <div className="row">
                        <div className="hospitableInfo">
                            <Translate content="hospitable" component="h1"/>
                            <Translate content="about_hospitable" component="p"/>
                        </div>

                    </div>
                    <div className="container hospitableSwiper">
                        <Swiper {...params}>
                            <div><img src={menu1} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:0 })}/></div>
                            <div><img src={menu2} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:1})}/></div>
                            <div><img src={menu3} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:2 })}/></div>
                            <div><img src={menu4} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:3 })}/></div>
                            <div><img src={menu5} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:4 })}/></div>
                            <div><img src={menu6} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:5 })}/></div>
                            <div><img src={menu7} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:6 })}/></div>
                            <div><img src={menu8} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:7 })}/></div>
                            <div><img src={menu9} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:8 })}/></div>
                            <div><img src={menu10} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:9 })}/></div>
                            <div><img src={menu11} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:10 })}/></div>
                            <div><img src={menu12} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:11 })}/></div>
                            <div><img src={menu13} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:12 })}/></div>
                            <div><img src={menu14} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:13 })}/></div>
                            <div><img src={menu15} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:14 })}/></div>
                            <div><img src={menu16} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:15 })}/></div>
                            <div><img src={menu17} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:16 })}/></div>
                            <div><img src={menu18} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:17 })}/></div>
                            <div><img src={menu19} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:18 })}/></div>
                            <div><img src={menu20} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:19 })}/></div>
                            <div><img src={menu21} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:20 })}/></div>
                            <div><img src={menu22} alt="menu" width="100%" onClick={() => this.setState({ isOpen: true, photoIndex:21 })}/></div>
                        </Swiper>
                        {isOpen && (
                            <Lightbox
                                mainSrc={images[photoIndex]}
                                nextSrc={images[(photoIndex + 1) % images.length]}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                onCloseRequest={() => this.setState({ isOpen: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + images.length - 1) % images.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % images.length,
                                    })
                                }
                            />
                        )}
                    </div>
                    <button type="button" className="orangeButton" onClick={this.contactTypeModel}><Translate
                        content="contact_us"/></button>
                </div>
                <Modal isOpen={this.state.contactModal} toggle={this.contactTypeModel} className="countryModal">
                    <ModalHeader toggle={this.contactTypeModel}><Translate content="contact_us"/></ModalHeader>
                    <ModalBody className="contactForm">
                        <Formik enableReinitialize
                                initialValues={{
                                    name: this.state.name,
                                    phone: this.state.phone,
                                    email: this.state.email,
                                    msg: this.state.msg,
                                }}
                                validationSchema={Yup.object().shape({

                                    name: Yup.string()
                                        .required(<Translate content="name_required"/>),
                                    phone: Yup.string()
                                        .matches(phoneRegEx, <Translate content="phone_not_valid"/>)
                                        .required(<Translate content="phone_required"/>),
                                    email: Yup.string()
                                        .email(<Translate content="invalid_email"/>)
                                        .required(<Translate content="email_required"/>),
                                    msg: Yup.string()
                                        .required(<Translate content="message_required"/>),


                                })}
                                onSubmit={values => {
                                    this.contactTypeModel();
                                    axios.post(window.$urlName + 'customers/contact', values, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Accept': 'application/json'
                                        }

                                    }).then((response) => {
                                        this.setState({
                                            msgAr:"شكرا لتواصلك سوف يتم التواصل معك قريبا",
                                            msgEn:"Thanks for your message will contact you near",
                                            errorMsgModel:true,
                                            name: "",
                                            phone: "",
                                            email: "",
                                            msg: "",
                                        });



                                    }).catch(function (error) {
                                        // alert(error.response.data.error);

                                    });

                                }}

                                render={({errors, status, touched}) => (
                                    <Form>
                                        <div className="form-group">
                                            <label htmlFor="name"><Translate content="name"/></label>


                                            <Field name="name" id="name" type="text" onChange={this.handelChange}
                                                   className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone"><Translate content="phone"/></label>


                                            <Field name="phone" id="phone" type="tel" onChange={this.handelChange}
                                                   className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email"><Translate content="email"/></label>
                                            <Field name="email" id="email" type="email" onChange={this.handelChange}
                                                   className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="msg"><Translate content="message"/></label>
                                            <Field name="msg" id="msg" component="textarea" onChange={this.handelChange}
                                                   className={'form-control' + (errors.msg && touched.msg ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="msg" component="div" className="invalid-feedback"/>
                                        </div>
                                        <button type="submit" onClick={this.startLoading}><Translate content="send"/>
                                        </button>


                                    </Form>
                                )}
                        />
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>

        )
    }
}

export default OldMudiaf