import React, {Component, Fragment} from "react";
import Translate from "react-translate-component";
import masterCard from "./component/assest/img/master.png";
import visa from "./component/assest/img/visa.png";
import {Redirect} from "react-router-dom"
import axios from "axios";
import connect from "react-redux/es/connect/connect";
import {RESETAPP} from "./actions/cartActions";
import basket from "./component/assest/img/basketDefult.png";
import {BallBeat} from "react-pure-loaders";
import {Modal, ModalBody} from "reactstrap";
import precent from "./component/assest/img/percent-2.svg"

let translate = require('counterpart');

class Payment extends Component {


    constructor(props) {
        super(props)
        let loggedIn = true;

        this.state = {
            token: localStorage.getItem("token"),
            selectedOption: "",
            redirect: false,
            orderId: null,
            items: JSON.parse(localStorage.getItem("finalProductArr")),
            totalPrice: localStorage.getItem("totalPrice"),
            condition: false,
            walletBalance: false,
            walletPay: false,
            cashPay: false,
            cardPay: false,
            offer: false,
            discountPrice: 0,
            loading: false,
            disabled: true,
            emptyProductError: false,
            msgAr: "",
            msgEn: "",
            ErrorMsgAr: "",
            ErrorMsgEn: "",
            errorMsgModel: false,
            orderErrorMsgModel: false,
            setAddressMsgModel: false,
            finalPrice: null,
            paymentLoading: true,
            addName: "",
            arAddress: "",
            enAddress: "",
            freeMeals: false,
            freeMealsArr: [],
            loggedIn
        };
    }

    async componentDidMount() {
        let branchId = parseInt(localStorage.getItem("branchId"));
        let totalPrice = (localStorage.getItem("descountPrice")) ? parseFloat(localStorage.getItem("descountPrice")) + parseFloat(localStorage.getItem("deliveryPrice")) : parseFloat(localStorage.getItem("total")) + parseFloat(localStorage.getItem("deliveryPrice"));
        localStorage.setItem("transactionTotal", totalPrice);
        let carOptionPayment = 'false';
        if(localStorage.getItem('carOption') ===  'true'){
            carOptionPayment = 'true';
        }

        this.setState({
            finalPrice: totalPrice,
        });

        await axios.get(window.$urlName + 'infos/allowed-payment-methods', {
            headers: {
                'Accept': 'application/json'
            },
            params: {
                branchId: branchId,
                carOption: carOptionPayment,
                price: totalPrice
            }
        }).then((response) => {
            if (response.data.data.allowCash === true) {
                if (totalPrice < 1000) {
                    this.setState({
                        cashPay: true,
                    });
                }

            }
            if (response.data.data.allowWallet === true) {
                axios.get(window.$urlName + 'customers/wallet-balance', {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token,
                        'Accept': 'application/json'
                    },
                    params: {
                        branchId: branchId
                    }
                }).then((response) => {
                    if (response.data.data.balance > 0) {
                        this.setState({
                            walletPay: true
                        })
                    }

                }).catch(function (error) {
                    // console.log(error.response)
                });
            }
            if (response.data.data.allowUrway === true) {
                this.setState({
                    cardPay: true
                })
            }
            this.setState({
                paymentLoading: false
            })

        }).catch(function (error) {
            // console.log(error.response)
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,
        }));
    };

    orderErrorTypeModel = () => {
        this.setState(prevState => ({
            orderErrorMsgModel: !prevState.orderErrorMsgModel,
        }));
    };

    setAddressTypeModel = () => {
        this.setState(prevState => ({
            setAddressMsgModel: !prevState.setAddressMsgModel,
        }));
        this.setState({
            loading: false,
        })
    };

    addressSubmit = (e) => {
        e.preventDefault();
        let values = {
            arAddress: this.state.arAddress,
            enAddress: this.state.enAddress,
            latitude: this.props.lat,
            longitude: this.props.lng,
            title: this.state.addName,
        };
        axios.post(window.$urlName + 'addresses', values, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.state.token,
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            localStorage.setItem("addressId", response.data.data.id)
            this.setAddressTypeModel()

        }).catch(function (error) {
            // console.error(error);
        })
    };

    handleOptionChange = (changeEvent) => {
        // if (changeEvent.target.value === "cash") {
        //     this.setState({
        //         ErrorMsgAr: "لسلامتكم, سيقتصر الدفع عند الاستلام عبر أجهزة الشبكة أو يمكنك الدفع الاونلاين.",
        //         ErrorMsgEn: " For your safety, Payment will only be received on ATM machine or you can pay online.",
        //         orderErrorMsgModel: true,
        //         loading: false,
        //     })
        // }
        let totalPrice = (localStorage.getItem("descountPrice")) ? parseFloat(localStorage.getItem("descountPrice")) + parseFloat(localStorage.getItem("deliveryPrice")) : parseFloat(localStorage.getItem("total")) + parseFloat(localStorage.getItem("deliveryPrice"));

        if (changeEvent.target.value === "wallet" && totalPrice > this.state.walletBalance) {
            this.setState({
                ErrorMsgAr: "رصيد المحفظة غير كافي, سيتم دفع المبلغ المتبقي عن طريق الدفع الإلكتروني",
                ErrorMsgEn: "The wallet balance is not enough, you will pay the remaining amount by the payment gateway.",
                orderErrorMsgModel: true,
                loading: false,
            })
        }

        if (this.state.token == null) {
            this.setState({
                selectedOption: "",
                loggedIn: false
            })
        } else {
            this.setState({
                selectedOption: changeEvent.target.value,
                loading: true,
                offer: false,
                disabled: false,

            });

            if (parseInt(localStorage.getItem("typeId")) === 1) {
                if (JSON.parse(localStorage.getItem("finalProductArr")).length === 0) {
                    this.setState({
                        emptyProductError: true,
                        loading: false,
                        disabled: false,
                    })

                }

                else {
                    if (localStorage.getItem("code") === '') {
                        let values = {
                            branchId: parseInt(localStorage.getItem("branchId")),
                            typeId: parseInt(localStorage.getItem("typeId")),
                            products: JSON.parse(localStorage.getItem("finalProductArr")),
                            paymentMethod: changeEvent.target.value,
                            deliveryType: localStorage.getItem("deliveryType"),
                            platform: "web",
                        };
                        this.offerSubmit(values)
                    } else {
                        let values = {
                            branchId: parseInt(localStorage.getItem("branchId")),
                            typeId: parseInt(localStorage.getItem("typeId")),
                            products: JSON.parse(localStorage.getItem("finalProductArr")),
                            paymentMethod: changeEvent.target.value,
                            deliveryType: localStorage.getItem("deliveryType"),
                            code: localStorage.getItem("code"),
                            platform: "web",
                        };
                        this.offerSubmit(values)


                    }

                }


            } else {
                if (JSON.parse(localStorage.getItem("finalProductArr")).length === 0) {
                    this.setState({
                        emptyProductError: true,
                        loading: false,
                        disabled: false,
                    })

                }
                else {
                    if (localStorage.getItem("code") === '') {
                        let values = {
                            branchId: parseInt(localStorage.getItem("branchId")),
                            typeId: parseInt(localStorage.getItem("typeId")),
                            products: JSON.parse(localStorage.getItem("finalProductArr")),
                            paymentMethod: changeEvent.target.value,
                            addressId: parseInt(localStorage.getItem("addressId")),
                            deliveryType: localStorage.getItem("deliveryType"),
                            platform: "web",
                        };
                        this.offerSubmit(values)

                    } else {
                        let values = {
                            branchId: parseInt(localStorage.getItem("branchId")),
                            typeId: parseInt(localStorage.getItem("typeId")),
                            products: JSON.parse(localStorage.getItem("finalProductArr")),
                            paymentMethod: changeEvent.target.value,
                            addressId: parseInt(localStorage.getItem("addressId")),
                            deliveryType: localStorage.getItem("deliveryType"),
                            code: localStorage.getItem("code"),
                            platform: "web",
                        };
                        this.offerSubmit(values)

                    }

                }

            }


        }


    };
    offerSubmit = (values) => {
        if (localStorage.getItem("addressId") === null && parseInt(localStorage.getItem("typeId")) === 2) {
            axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.props.lat + ',' + this.props.lng + '&language=ar&key=AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw')
                .then((response) => {
                    this.setState({
                        arAddress: response.data.results[0].formatted_address
                    });
                });
            axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.props.lat + ',' + this.props.lng + '&language=en&key=AIzaSyDPInNXxJ-Drq0RXS45apFP3snnhX5VTpw')
                .then((response) => {
                    this.setState({
                        enAddress: response.data.results[0].formatted_address
                    });
                });
            this.setAddressTypeModel()
        } else {
            axios.post(window.$urlName + 'offers/check-offers', values, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',

                }
            }).then((response) => {
                if (response.data.data.minPrice === null) {
                    if (response.data.data.isOffer === true) {
                        this.setState({
                            offer: true,
                            loading: false,
                            discountPrice: response.data.data.price,
                            disabled: true,

                        });

                    } else {
                        if (response.data.data.freeMeals.length) {
                            this.setState({
                                freeMeals: true,
                                freeMealsArr: response.data.data.freeMeals,
                                loading: false,
                                disabled: true,

                            })

                        } else {
                            this.setState({
                                loading: false,
                                disabled: true,

                            })
                        }
                    }
                } else {
                    this.setState({
                        msgAr: " الحد الأدنى للطلب " + response.data.data.minPrice + " ",
                        msgEn: " Order total price must be at least " + response.data.data.minPrice + " ",
                        errorMsgModel: true,
                        loading: false,
                        disabled: false,

                    });

                }

            }).catch(error => {
                if (error.response.status === 401) {
                    this.setState({
                        selectedOption: "",
                        loggedIn: false,
                    })
                } else {
                    this.getError(error.response.data.error)
                }
            });
        }
    };

    getError = (msg) => {
        if (msg === "branch not support branch pick up") {
            this.setState({
                ErrorMsgAr: "الفرع لا يدعم خدمة توصيل الطالبات",
                ErrorMsgEn: "branch not support branch pick up",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "branch not support delivery") {
            this.setState({
                ErrorMsgAr: "الفرع لا يدعم خدمة توصيل المنزل",
                ErrorMsgEn: "branch not support delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "branch not exist") {
            this.setState({
                ErrorMsgAr: "الفرع خارج نطاق الخدمة",
                ErrorMsgEn: "branch not exist",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "branch not active") {
            this.setState({
                ErrorMsgAr: "الفرع غير متاح حاليا",
                ErrorMsgEn: "branch not active",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "branch not open") {
            this.setState({
                ErrorMsgAr: "الفرع مغلق الأن",
                ErrorMsgEn: "branch not open",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "meal not exist") {
            this.setState({
                ErrorMsgAr: "الوجبة غير متاحة حاليا",
                ErrorMsgEn: "meal not exist",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "meal not active") {
            this.setState({
                ErrorMsgAr: "الوجبة غير متاحة حاليا",
                ErrorMsgEn: "meal not active",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "product not delivery") {
            this.setState({
                ErrorMsgAr: "الوجبة غير متاحة للتوصيل",
                msgEn: "product not delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "additional not delivery") {
            this.setState({
                ErrorMsgAr: "الإضافات غير متاحة للتوصيل",
                ErrorMsgEn: "additional not delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "extra not exist") {
            this.setState({
                ErrorMsgAr: "الزيادات غير متاحة",
                ErrorMsgEn: "extra not exist",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "extra not belong to product") {
            this.setState({
                ErrorMsgAr: "الزيادات غير متوفرة مع الوجبة",
                ErrorMsgEn: "extra not belong to product",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "The selected code is invalid.") {
            this.setState({
                ErrorMsgAr: "الكود غير صحيح",
                ErrorMsgEn: "The selected code is invalid.",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else if (msg === "address not valid") {
            this.setState({
                ErrorMsgAr: "العنوان غير صحيح",
                ErrorMsgEn: "address not valid",
                orderErrorMsgModel: true,
                loading: false,
            })
        }
        else if (msg === "time not in branch time") {
            this.setState({
                ErrorMsgAr: "الفرع مغلق في الوقت المحدد",
                ErrorMsgEn: "time not in branch time",
                orderErrorMsgModel: true,
                loading: false,
            })
        }
        else if (msg === "branch not support fast delivery") {
            this.setState({
                ErrorMsgAr: "الفرع لا يوفر التوصيل السريع",
                ErrorMsgEn: "branch not support fast delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        }
        else if (msg === "branch busy") {
            this.setState({
                ErrorMsgAr: "الفرع مشغول، تقدر تطلب بعد الزحام.",
                ErrorMsgEn: "This branch is currently busy. You can order for later when branch is not busy.",
                orderErrorMsgModel: true,
                loading: false,
            })
        }
        else if (msg === "branch not support ordinary delivery") {
            this.setState({
                ErrorMsgAr: "الفرع لا يوفر التوصيل العادي",
                ErrorMsgEn: "branch not support ordinary delivery",
                orderErrorMsgModel: true,
                loading: false,
            })
        } else {
            this.setState({
                ErrorMsgAr: msg,
                ErrorMsgEn: msg,
                orderErrorMsgModel: true,
                loading: false,
            })
        }
    };

    paymentSubmit = (values) => {
        if(localStorage.getItem('carOption') ===  'true'){
            values.carOption = true;
            values.carModel = localStorage.getItem('carModel');
            values.carColor = localStorage.getItem('carColor');
            values.carNumber = localStorage.getItem('carNumber');
            values.carNotes = localStorage.getItem('carNotes');
        }
        axios.post(window.$urlName + 'orders', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then((response) => {
            if(typeof response.data.data.url === "undefined"){
                this.setState({
                    redirect: true,
                    orderId: response.data.data.orderId
                });
                localStorage.setItem("menuRoute", "false");
                localStorage.setItem("orderId", response.data.data.orderId);
                this.props.RESETAPP();
            }else {
                window.location.href = response.data.data.url
                this.props.RESETAPP();
            }

        }).catch(error => {
            this.getError(error.response.data.error)
        });
    };

    handelSubmit = (e) => {
        e.preventDefault();
        this.setState({
            disabled: false,

        })

        if (this.state.token == null) {
            this.setState({
                selectedOption: "",
                loggedIn: false
            })
        } else {
            if (localStorage.getItem("code") === '') {
                if (parseInt(localStorage.getItem("typeId")) === 1) {
                    let values = {
                        branchId: parseInt(localStorage.getItem("branchId")),
                        typeId: parseInt(localStorage.getItem("typeId")),
                        deliveryType: localStorage.getItem("deliveryType"),
                        products: JSON.parse(localStorage.getItem("finalProductArr")),
                        paymentMethod: this.state.selectedOption,
                        platform: "web",
                        notes: localStorage.getItem("notes"),
                        now: localStorage.getItem("now"),
                        date: localStorage.getItem("date"),
                        time: localStorage.getItem("time"),

                    };
                    this.paymentSubmit(values)
                } else {
                    let values = {
                        branchId: parseInt(localStorage.getItem("branchId")),
                        typeId: parseInt(localStorage.getItem("typeId")),
                        addressId: parseInt(localStorage.getItem("addressId")),
                        deliveryType: localStorage.getItem("deliveryType"),
                        products: JSON.parse(localStorage.getItem("finalProductArr")),
                        paymentMethod: this.state.selectedOption,
                        platform: "web",
                        notes: localStorage.getItem("notes"),
                        now: localStorage.getItem("now"),
                        date: localStorage.getItem("date"),
                        time: localStorage.getItem("time"),
                    };
                    this.paymentSubmit(values)
                }

            } else {

                if (parseInt(localStorage.getItem("typeId")) === 1) {
                    let values = {
                        branchId: parseInt(localStorage.getItem("branchId")),
                        typeId: parseInt(localStorage.getItem("typeId")),
                        deliveryType: localStorage.getItem("deliveryType"),
                        products: JSON.parse(localStorage.getItem("finalProductArr")),
                        code: localStorage.getItem("code"),
                        paymentMethod: this.state.selectedOption,
                        platform: "web",
                        notes: localStorage.getItem("notes"),
                        now: localStorage.getItem("now"),
                        date: localStorage.getItem("date"),
                        time: localStorage.getItem("time"),
                    };
                    this.paymentSubmit(values)

                } else {
                    let values = {
                        branchId: parseInt(localStorage.getItem("branchId")),
                        typeId: parseInt(localStorage.getItem("typeId")),
                        addressId: parseInt(localStorage.getItem("addressId")),
                        deliveryType: localStorage.getItem("deliveryType"),
                        products: JSON.parse(localStorage.getItem("finalProductArr")),
                        code: localStorage.getItem("code"),
                        paymentMethod: this.state.selectedOption,
                        platform: "web",
                        notes: localStorage.getItem("notes"),
                        now: localStorage.getItem("now"),
                        date: localStorage.getItem("date"),
                        time: localStorage.getItem("time"),
                    };
                    this.paymentSubmit(values)

                }


            }


        }


    };

    backCard = () => {
        const location =
            {pathname: '/food-list'};
        this.props.history.push(location);
    };

    displayEmpty = () => {
        this.setState({condition: !this.state.condition})
    };

    render() {
        if (this.state.loggedIn === false) {
            const location =
                {pathname: '/sign-in', state: {from: 'payment'}};
            this.props.history.push(location);
        }
        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: `/order/${this.state.orderId}`,
            }}
            />
        }
        let meals = this.state.freeMealsArr.map((meal) => {
            return (
                <div key={meal.enName}>
                    {translate.getLocale() === 'ar' ? <p>{meal.arName}</p> :
                        <p>{meal.enName}</p>}
                </div>
            )
        });
        let items = JSON.parse(localStorage.getItem("reducer") || "[]").length ?
            (
                JSON.parse(localStorage.getItem("reducer") || "[]").map((item, index) => {
                    return (

                        <li className="whiteBasketRow" key={index}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    {translate.getLocale() === 'ar' ? <p>{item.arName}</p> :
                                        <p>{item.enName}</p>}
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    {translate.getLocale() === 'ar' ? <span className="mealSize">{item.sizeAr}</span> :
                                        <span className="mealSize">{item.sizeEn}</span>}
                                </div>
                            </div>
                            <div className="row priceItemRow">
                                <div className="col-lg-6 col-md-6">
                                    <Translate content="price" className="itemPrice"/><span
                                    className="itemPriceNum">{item.itemTotalPrice}</span>

                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <Translate content="quantity" className="itemPrice"/><span
                                    className="itemPriceNum">{item.quantity}</span>

                                </div>

                            </div>

                        </li>

                    )
                })) :

            (
                <div className="noBasketSec" id="basketEmpty">
                    <p className="nonOrders">
                        <img src={basket} alt="basket"/>
                        <Translate content="nothing"/>
                    </p>
                </div>
            );


        return (
            <Fragment>

                <div className="container" id="basketFull">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="loadingSec">
                                <BallBeat
                                    color={'white'}
                                    loading={this.state.loading}
                                />
                            </div>
                            <div className="basketSide">
                                <h5><Translate content="you_have_ordered"/></h5>
                                {items}

                                <div className="taxSec">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <Translate content="tax"/>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                        <span
                                            className="arialFont">{(this.state.finalPrice * 0.15).toFixed(1)} {translate.getLocale() === 'ar' ?
                                            <span>{localStorage.getItem("arCurrency")}</span> :
                                            <span>{localStorage.getItem("enCurrency")}</span>}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="basketTotal">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <Translate content="total_price"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <span
                                            className="arialFont">{(localStorage.getItem("descountPrice")) ? (parseFloat(localStorage.getItem("descountPrice")) + parseFloat(localStorage.getItem("deliveryPrice"))).toFixed(1) : (parseFloat(localStorage.getItem("total"))+ parseFloat(localStorage.getItem("deliveryPrice"))).toFixed(1)} {translate.getLocale() === 'ar' ?
                                            <span>{localStorage.getItem("arCurrency")}</span> :
                                            <span>{localStorage.getItem("enCurrency")}</span>}</span>
                                    </div>
                                </div>
                            </div>

                            {this.state.offer ? <div className="basketTotalDiscount">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <Translate content="total_price_after_disc"/>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <span
                                            className="arialFont">{(this.state.discountPrice).toFixed(1)} {translate.getLocale() === 'ar' ?
                                            <span>{localStorage.getItem("arCurrency")}</span> :
                                            <span>{localStorage.getItem("enCurrency")}</span>}</span>
                                    </div>
                                </div>
                            </div> : null}

                            {this.state.freeMeals ?
                                <div className="freeMealsSec">
                                    <h3><Translate content="you_win"/> <img src={precent} alt="precent"/></h3>
                                    {meals}
                                </div>
                                : null}

                            <span className="addMore" onClick={this.backCard}><Translate
                                content="add_more_item"/></span>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            {this.state.emptyProductError ? <div className="emptyProductError">
                                <Translate content="product_must_be_faild"/>
                            </div> : null}
                            {this.state.minPriceMsg ? <div className="emptyProductError">
                                <Translate content="minPriceMsg"/>
                            </div> : null}
                            <form onSubmit={this.handelSubmit}>
                                <div className="paymentBody" id="paymentBody">
                                    <Translate content="payment" component="h1"/>
                                    {this.state.paymentLoading ? <div className="loadingSec">
                                            <BallBeat
                                                color={'white'}
                                                loading={this.state.paymentLoading}
                                            />
                                        </div> :

                                        <div className="row paymentRadio">
                                            {this.state.walletPay ?
                                                <div className="radio">
                                                    <input type="radio" value="wallet" name="payment" id="wallet"
                                                           checked={this.state.selectedOption === 'wallet'}
                                                           onChange={this.handleOptionChange} required/>
                                                    <label htmlFor="wallet"><Translate
                                                        content="wallet_payment"/></label>
                                                </div> : null}
                                            {this.state.cashPay ?
                                                <div className="radio">
                                                    <input type="radio" value="cash" name="payment" id="cash"
                                                           checked={this.state.selectedOption === 'cash'}
                                                           onChange={this.handleOptionChange}/>
                                                    <label htmlFor="cash"> <Translate content="arrivalPayment"/>
                                                    </label>
                                                </div>
                                                : null}
                                            {this.state.cardPay ?
                                                <div className="radio">

                                                    <input type="radio" value="tap" name="payment" id="tap"
                                                           checked={this.state.selectedOption === 'tap'}
                                                           onChange={this.handleOptionChange}/>
                                                    <label htmlFor="tap"><Translate
                                                        content="creditCardPayment"/></label>
                                                    <div className="creditType">
                                                        <img src={masterCard} alt="masterCard"/>
                                                        <img src={visa} alt="visa"/>
                                                    </div>
                                                </div>
                                                : null}


                                        </div>
                                    }
                                </div>
                                <div className="paymentButton">
                                    <button type="submit" disabled={!this.state.selectedOption || !this.state.disabled}>
                                        <Translate
                                            content="confirm"/></button>
                                </div>
                            </form>
                        </div>


                    </div>


                </div>


                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ?
                                <p> {this.state.msgAr}{localStorage.getItem("lang") === 'en' ? localStorage.getItem("enCurrency") : localStorage.getItem("arCurrency")}
                                    <Translate content="without_delivery"/></p> :
                                <p>{this.state.msgEn}{localStorage.getItem("lang") === 'en' ? localStorage.getItem("enCurrency") : localStorage.getItem("arCurrency")}
                                    <Translate content="without_delivery"/></p>}
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.orderErrorMsgModel} toggle={this.orderErrorTypeModel}
                       className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ?
                                <p> {this.state.ErrorMsgAr}</p> :
                                <p>{this.state.ErrorMsgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.setAddressMsgModel} toggle={this.setAddressTypeModel}
                       className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="address_name" component="p"/>
                            <form onSubmit={this.addressSubmit}>

                                <div className="form-group">
                                    <input type="text" id="addName" value={this.state.addName}
                                           onChange={this.handleChange} className="form-control" required/>
                                    <button type="submit" className="orangeButton addButt"><Translate content="save"/>
                                    </button>
                                </div>
                            </form>

                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lat: state.lat,
        lng: state.lng,
    }
};
const mapDispatchToProps = (dispatch) => {

    return {
        RESETAPP: () => {
            dispatch(RESETAPP())
        }

    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Payment)
