import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import success from "./component/assest/img/success.svg";
import failed from "./component/assest/img/faild.svg";
import axios from "axios";
import {BallBeat} from "react-pure-loaders";
import {RESETAPP} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";

class InvoiceSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    async componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let tapId = params.get('tap_id');

        if(tapId !== ""){
            await axios.get(window.$urlName + 'orders/tap/invoice-status/'+tapId ,{
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            }
            ).then((response) => {
                document.getElementById("paySuccessBody").style.display = "block";
                this.setState({
                    loading: false
                })
                this.props.RESETAPP()
           
            }).catch(function (error) {
                document.getElementById("payNotSuccessBody").style.display = "block";
                this.setState({
                    loading: false
                })
                // console.log(error.response)
            });
        } else {
            document.getElementById("payNotSuccessBody").style.display = "block";
            this.setState({
                loading: false
            })
        }
    }

    render() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const orderId = parseInt(params.get('TrackId'));
        const TranId = params.get('TranId');
        return (
            <Fragment>
                <div style={{height: "400px"}}>
                    <div className="parent">
                        <div className="child">
                            <div className="loadingSec">
                                <BallBeat
                                    color={'white'}
                                    loading={this.state.loading}
                                />
                            </div>
                            <div className="paySuccessBody" id="paySuccessBody" style={{display: "none"}}>
                                <img src={success} alt="success"/>
                                <Translate content="success_payment" component="h2"/>
                            </div>
                            <div className="payNotSuccessBody" id="payNotSuccessBody" style={{display: "none"}}>
                                <img src={failed} alt="failed"/>
                                <Translate content="notSuccess_payment" component="h2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


const mapDispatchToProps = (dispatch) => {

    return {
        RESETAPP: () => {
            dispatch(RESETAPP())
        }
    }
};
export default connect(null, mapDispatchToProps)(InvoiceSuccess)
