import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import MudiafBanner from "./mudiafBanner";
import Lightbox from 'react-image-lightbox';
import './component/assest/css/swiper.min.css';
import 'react-image-lightbox/style.css';

import {BallBeat} from "react-pure-loaders";

class MudiafImages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: localStorage.getItem("token"),
            imagesArr: [],
            loading:false,
            isOpen:false,
            photoIndex: 0,
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };
        axios.get(window.$urlName + 'mdiaf/images', {
            headers
        }).then((response) => {
            this.setState({
                imagesArr: response.data.data.mdiafImages,
                loading:false
            })
        }).catch(function (error) {
             //console.log(error.response)
        });
    }

    render() {
        const { photoIndex, isOpen } = this.state;

        let images = this.state.imagesArr.map((image, index) => {
            return (
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={index}  onClick={() => this.setState({ isOpen: true, photoIndex: index })}>
                    <div className="branchSingleCard">
                        <div className="branchImg" style={{backgroundImage: `url(${image.image})`}}>
                        </div>
                        <div className="userData">
                            <h3><Translate content="by"/> : {image.user}</h3>
                            <p> {image.createdAt} </p>
                        </div>
                        
                    </div>
                </div>
            )
        });
        return (
            <Fragment>
                <MudiafBanner></MudiafBanner>

                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf`}}>
                                <Translate content="mudiaf_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-services`}}>
                                <Translate content="mudiaf_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-inquires`}}>
                                <Translate content="mudiaf_inquires"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items mudiaf-items-active">
                            <Link to={{pathname: `/medhyaf-images`}}>
                                <Translate content="mudiaf_images"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-contact`}}>
                                <Translate content="mudiaf_contact"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <div className="container">
                        <div className="row">
                        {images.length>0 ? 
                            <div className="container hospitableSwiper">
                                <div className="row mdiafGallery">
                                    {images}
                                </div>
                                {/* <Swiper {...params}>
                                {images}
                                </Swiper> */}
                                {isOpen && (
                                <Lightbox
                                    mainSrc={this.state.imagesArr[photoIndex].image}
                                    nextSrc={this.state.imagesArr[(photoIndex + 1) % this.state.imagesArr.length].image}
                                    prevSrc={this.state.imagesArr[(photoIndex + this.state.imagesArr.length - 1) % this.state.imagesArr.length].image}
                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                    onMovePrevRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + this.state.imagesArr.length - 1) % this.state.imagesArr.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        this.setState({
                                            photoIndex: (photoIndex + 1) % this.state.imagesArr.length,
                                        })
                                    }
                                />
                            )}
                            </div>
                        : 
                            <Translate content="noResults" component="p" className="no_results"/>
                        }
                        
                        {this.state.token? 
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <button type="button" style={{margin:"30px auto"}} className="orangeButton">
                                    <Link to={{pathname: `/medhyaf-share`}}>
                                        <Translate content="sharePhotos"/>
                                    </Link>
                                </button>
                            </div>
                        : ""}
                      

                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default MudiafImages