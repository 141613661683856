import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from 'react-router-dom';
import awafiSA from "./component/assest/img/awafiSA.jpg";

class AwafiSA extends Component {
    render(){
        return(
            <Fragment>
                <div className="container">
                    <div className="termsBody">
                        <img src={awafiSA} alt="vat" width="100%"/>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default AwafiSA
