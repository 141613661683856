import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import moneyIcon from "./component/assest/img/moneyIcon.svg";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import SalesBanner from "./salesBanner";

import {BallBeat} from "react-pure-loaders";

let translate = require('counterpart');

class SalesMenus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menus: [],
            currAr: "ريال",
            currEn: "SAR",
            loading:false,
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };
        axios.get(window.$urlName + 'sales/menus', {
            headers
        }).then((response) => {
            this.setState({
                menus: response.data.data.salesMenus,
                loading:false
            })
        }).catch(function (error) {
             console.log(error.response)
        });
    }

    render() {
        let menus = this.state.menus.map((menu) => {
            return (

                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={menu.id}>
                    <div className="branchSingleCard">

                        <div className="branchImg" style={{backgroundImage: `url(${menu.image})`}}>
                        </div>
                        {translate.getLocale() === 'ar' ? <h3>{menu.arName}</h3> :
                            <h3>{menu.enName}</h3>}
                        <p> <img src={moneyIcon} alt="money"/> <Translate content="person_cost"/>  {menu.pricePersonSAR}
                            {translate.getLocale() === 'ar' ? <span>{this.state.currAr}</span> : <span>{this.state.currEn}</span>} </p>

                        <Link to={{pathname: `/companies-menu/${menu.id}`}}>
                            <button type="button" className="callDerive" style={{background:"#DB095B"}}>
                                    <Translate content="openMenu"/>
                            </button>
                        </Link>
                    </div>

                </div>
            )
        });
        return (
            <Fragment>
                <SalesBanner></SalesBanner>
                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items companies-items mudiaf-items-active">
                            <Link to={{pathname: `/companies`}}>
                                <Translate content="companies_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-services`}}>
                                <Translate content="companies_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-clients`}}>
                                <Translate content="companies_clients"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items companies-items">
                            <Link to={{pathname: `/companies-contact`}}>
                                <Translate content="contact_us"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="hospitableInfo">
                                <Translate content="companiesSubscriptions" component="h1"/>
                                <Translate content="about_companies" component="p"/>
                            </div>

                        </div>
                        <div className="row">
                            {menus.length>0 ? menus : 
                                <Translate content="noResults" component="p" className="no_results"/>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SalesMenus