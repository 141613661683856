import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Swiper from "react-id-swiper";
import {BallBeat} from "react-pure-loaders";
import Sticky from "react-sticky-el";
import moneyIcon from "./component/assest/img/moneyIcon.svg";
import SalesBanner from "./salesBanner";

let translate = require('counterpart');

class SalesSingleMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menus: [],
            menuId: window.location.href.split('/')[4],
            menuNameEn: "",
            menuNameAr: "",
            menuDescriptionEn: "",
            menuDescriptionAr: "",
            menuMeals: [],
            image:"",
            minPersonsNumber: 0,
            pricePersonSAR: 0,
            loading:false
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };

        axios.get(window.$urlName + 'sales/menus/'+ this.state.menuId, {
            headers
        }).then((response) => {
            this.setState({
                minPersonsNumber: response.data.data.minPersonsNumber,
                pricePersonSAR: response.data.data.pricePersonSAR,
                menuNameEn: response.data.data.enName,
                menuNameAr: response.data.data.arName,
                menuDescriptionEn: response.data.data.enDescription,
                menuDescriptionAr: response.data.data.arDescription,
                menuMeals: response.data.data.meals,
                image: response.data.data.image,
                loading:false
            })
        }).catch(function (error) {
            //window.location.pathname = "/404"
        });

        axios.get(window.$urlName + 'sales/menus', {
            headers
        }).then((response) => {
            this.setState({
                menus: response.data.data.salesMenus,
                loading:false
            })
        }).catch(function (error) {
            // console.log(error.response)
        });
    }

    render() {
        const menusSwiper = {
            slidesPerView: 3,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                },
                768: {
                    slidesPerView: 2,
                },
                640: {
                    slidesPerView: 1,
                },
                320: {
                    slidesPerView: 1,
                }
            },
        };

        let others = this.state.menus.map((other) => {
            return (
                <div key={other.id}>
                    <div className="branchSingleCard">
                        <div className="branchImg" style={{backgroundImage: `url(${other.image})`}}>
                        </div>
                        {translate.getLocale() === 'ar' ? <h3>{other.arName}</h3> :
                            <h3>{other.enName}</h3>}
                        <p> <img src={moneyIcon} alt="money"/> <Translate content="person_cost"/> {other.pricePersonSAR} <Translate content="SAR"/>
                            {translate.getLocale() === 'ar' ? <span>{this.state.currAr}</span> : <span>{this.state.currEn}</span>} </p>

                        <a href={'/companies-menu/' + other.id}>
                            <button type="button" className="callDerive" style={{background:"#DB095B"}}>
                                <Translate content="openMenu"/>
                            </button>
                        </a>
                    </div>
                </div>
            )
        });


        let meals = this.state.menuMeals.map((meal) => {
            return (
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4" key={meal.id}>
                    <div className="branchImg" style={{backgroundImage: `url(${meal.image})`}}></div>
                    {translate.getLocale() === 'ar' ? <h4><Translate content="number"/> ({meal.pivotSalesMenuSalesMeal.quantity}) {meal.arName}</h4> : <h4><Translate content="number"/> ({meal.pivotSalesMenuSalesMeal.quantity}) {meal.enName}</h4>}                    
                </div>
            )
        });

        return (
            <Fragment>
                <SalesBanner></SalesBanner>

                <div className="container-fluid menusDiv">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <Sticky stickyClassName="stickyFilter">
                        <div className="mudiaf-bar">
                            <div className="mudiaf-items companies-items mudiaf-items-active">
                                <Link to={{pathname: `/companies`}}>
                                    <Translate content="companies_menus"/>
                                </Link>
                            </div>
                            <div className="mudiaf-items companies-items">
                                <Link to={{pathname: `/companies-services`}}>
                                    <Translate content="companies_services"/>
                                </Link>
                            </div>                        
                            <div className="mudiaf-items companies-items">
                                <Link to={{pathname: `/companies-clients`}}>
                                    <Translate content="companies_clients"/>
                                </Link>
                            </div>
                            <div className="mudiaf-items companies-items">
                                <Link to={{pathname: `/companies-contact`}}>
                                    <Translate content="contact_us"/>
                                </Link>
                            </div>
                        </div>
               
                    </Sticky>

                    <div className="row salesMenu">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="row salesMenuMeal">
                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                    {translate.getLocale() === 'ar' ? <h3>{this.state.menuNameAr}</h3> : <h3>{this.state.menuNameEn}</h3>}
                                    {translate.getLocale() === 'ar' ? <p>{this.state.menuDescriptionAr}</p> : <p>{this.state.menuDescriptionEn}</p>}
                                    <p><strong><Translate content="person_cost" component="span"/></strong>: {this.state.pricePersonSAR} <Translate content="SAR"/></p>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <a href={'/companies-inquire/' + this.state.menuId}>
                                        <button type="button" className="callDerive" style={{background:"#DB095B"}}>
                                            <Translate content="book_menu"/>
                                        </button>
                                    </a>
                                </div>
                            </div>
                           
                            <div className="row salesMenuMeal">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    {this.state.menuMeals.length > 0 ?
                                    <p><strong><Translate content="meals" component="span"/></strong>:</p>
                                    :""}
                                </div>
                                {meals}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="branchImg" style={{backgroundImage: `url(${this.state.image})`}}>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="container-fluid branchesSec anotherMenus">
                    <Translate content="anotherMenus" component="h1"/>
                    <div className="row menusBar">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            {this.state.menus.length !== 0 ? (
                                <Swiper {...menusSwiper} >
                                    {others}
                                </Swiper>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SalesSingleMenu