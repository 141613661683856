import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import Translate from "react-translate-component";
import Select from 'react-select';
import countryList from 'react-select-country-list';
import axios from "axios";
import {BallBeat} from "react-pure-loaders";
import {Modal, ModalBody} from "reactstrap";
import {authounticateUser} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";
let translate = require('counterpart');

class Loyalty extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        let loggedIn = true;

        if (token == null) {
            loggedIn = false
        }

        this.options = countryList().getData();

        this.state = {
            token: localStorage.getItem("token"),
            selectedOption: "male",
            selectedCheckboxOption: "",
            options: this.options,
            value: null,
            birthDate: '',
            name: '',
            phone: '',
            email: '',
            nationality: '',
            userPoint: 0,
            pointsBalance: null,
            pointPrice: null,
            loading: true,
            msgAr:"",
            msgEn:"",
            verificationCode:"",
            errorMsgModel: false,
            arabCountry : [],
            disableButt:true,
            loggedIn
        };
    }

    componentDidMount() {

        this.getArabCountry()
        if(this.state.token){
            axios.get(window.$urlName + 'customers/check-loyalty', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.state.token,
                }
                }).then((response) => {
                    if (response.data.data.check === 1) {
                        axios.get(window.$urlName + 'points', {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + this.state.token,
                            }
                        }).then((response) => {
                            this.setState({
                                pointsBalance: response.data.data.pointsBalance,
                                pointPrice: response.data.data.pointPrice,
                                loading: false
                            });

                        this.checkloyalty()

                        }).catch(function (error) {
                            if (error.response.status === 403) {
                                window.location.pathname = "/verification-code"
                            } else {
                                // const location =
                                //     {pathname: '/sign-in', state: {from: 'loyalty'}};
                                // this.props.history.push(location);                  
                            }

                        }.bind(this));

                    } else {
                        this.setState({
                            loading: false
                        })
                        this.getloyaltyForm();
                    }
                }).catch(function (error) {
                    if (error.response.status === 403) {
                        window.location.pathname = "/verification-code"
                    } else {
                        this.setState({
                            loading: false
                        });
                        this.getloyaltyForm();

                        // const location =
                        //     {pathname: '/sign-in', state: {from: 'loyalty'}};
                        // this.props.history.push(location);
                    }
                }.bind(this));
        }else{
            this.setState({
                loading: false,
            })
            this.getloyaltyForm();

        }

        let number = document.getElementById('userPoint');
        number.onkeydown = function (e) {
            if (!((e.keyCode > 95 && e.keyCode < 106)
                || (e.keyCode > 47 && e.keyCode < 58)
                || e.keyCode === 8)) {
                return false;
            }
        }

        let today = new Date(),
            day = today.getDate(),
            month = today.getMonth() + 1,
            year = today.getFullYear() - 15;

        if (day < 10) {
            day = '0' + day
        }

        if (month < 10) {
            month = '0' + month
        }

        today = year + '-' + month + '-' + day;

        document.getElementById("birthDate").setAttribute("max", today);
        document.getElementById("birthDate").setAttribute("value", today);
    }

    getArabCountry = () =>{
        let {arabCountry} = this.state;
        let sa = {
            value:"sa",
            label:countryList().getLabel("sa"),
        };
        let bh = {
            value:"bh",
            label:countryList().getLabel("bh"),
        };
        let eg = {
            value:"eg",
            label:countryList().getLabel("eg"),
        };
        let ae = {
            value:"ae",
            label:countryList().getLabel("ae"),
        };
        let kw = {
            value:"kw",
            label:countryList().getLabel("kw"),
        };
        let qa = {
            value:"qa",
            label:countryList().getLabel("qa"),
        };
        let om = {
            value:"om",
            label:countryList().getLabel("om"),
        };
        let sy = {
            value:"sy",
            label:countryList().getLabel("sy"),
        };
        let iq = {
            value:"iq",
            label:countryList().getLabel("iq"),
        };
        let ye = {
            value:"ye",
            label:countryList().getLabel("ye"),
        };
        arabCountry.push(sa,bh,eg,ae,kw,qa,om,sy,iq,ye);
        let otherCountries = countryList().getData();
            otherCountries.map((obj) => {
                return(
                    arabCountry.push(obj)
                )
            })
        };

    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,
        }));
    };

    checkloyalty = () => {
        document.getElementById('pointsForm').style.display = "block";
    };

    getloyaltyForm = () => {
        document.getElementById('loyaltyForm').style.display = "block";
        document.getElementById('pointsForm').style.display = "none";
    };

    handleOptionChange = (changeEvent) => {
        this.setState({
            selectedOption: changeEvent.target.value
        });
    };

    handleOptionCheckboxClick = (changeEvent) => {
        this.setState({
            selectedCheckboxOption: changeEvent.target.value
        });
    };

    changeHandler = value => {
        this.setState({
            value: value,
            nationality: value.label
        })
    };

    handelInput = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    displayForm = () => {
        document.getElementById('loyaltyForm').style.display = "none";
        document.getElementById('pointsForm').style.display = "block";
    };

    displayVerification = () => {
        document.getElementById('loyaltyForm').style.display = "none";
        document.getElementById('verificationForm').style.display = "block";
    };

    displaySuccess = () => {
        document.getElementById('verificationForm').style.display = "none";
        document.getElementById('successForm').style.display = "block";
    };

    handelSubmit = (e) => {
        e.preventDefault();

         if (this.state.nationality === "") {
            this.setState({
                msgAr:"من فضلك اختر البلد",
                msgEn:"Please choose country",
                errorMsgModel:true
            });
        }
        else if(this.state.selectedCheckboxOption === "") {
            this.setState({
                msgAr:"من فضلك وافق علي الشروط والأحكام",
                msgEn:"Please confirm terms and conditions",
                errorMsgModel:true
            });
        }
        else {
            if(this.state.token){
                let JoinLink = 'customers/loyalty';
                let values = {
                    code: this.state.verificationCode,
                    nationality: this.state.nationality,
                    email: this.state.email,
                    birthDate: this.state.birthDate,
                    gender: this.state.selectedOption,
                    name: this.state.name,
                    phone: this.state.phone
                };
        
                axios.post(window.$urlName + JoinLink, values, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + this.state.token,
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                    axios.get(window.$urlName + 'points', {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + this.state.token,
                        }
                    }).then((response) => {
                        this.setState({
                            disableButt:false,
                            pointsBalance: response.data.data.pointsBalance,
                            userPoint: response.data.data.pointsBalance,
                            pointPrice: response.data.data.pointPrice,
                        });
                        localStorage.setItem("userName", this.state.name);
                        this.props.authounticateUser();
                        this.checkloyalty()
                    }).catch(function (error) {
                        this.setState({
                            msgAr: error.response.data.error,
                            msgEn:error.response.data.error,
                            errorMsgModel:true
                        });
                    });
                    this.setState({
                        loading: false
                    })
                    this.displayForm()
                }).catch(function (error) {
                    this.setState({
                        msgAr: error.response.data.error,
                        msgEn:error.response.data.error,
                        errorMsgModel:true
                    });
                })
            }
            else{
                let values = {
                    phone: this.state.phone
                };

                axios.post(window.$urlName + 'customers/awafi-code-issue', values, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {
                    this.setState({
                        loading: false
                    })
                    this.displayVerification()
                }).catch(function (error) {
                    this.setState({
                        msgAr: error.response.data.error,
                        msgEn:error.response.data.error,
                        errorMsgModel:true
                    });
                })
            }
        }
    };

    handelVerificationSubmit = (e) => {
        e.preventDefault();

         if (this.state.verificationCode === "") {
            this.setState({
                msgAr:"من فضلك ادخل كود التفعيل",
                msgEn:"Please enter verification code",
                errorMsgModel:true
            });
        }

        else {
            let JoinLink = 'customers/loyalty-by-phone';
            let values = {
                code: this.state.verificationCode,
                nationality: this.state.nationality,
                email: this.state.email,
                birthDate: this.state.birthDate,
                gender: this.state.selectedOption,
                name: this.state.name,
                phone: this.state.phone
            };

            axios.post(window.$urlName + JoinLink, values, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + this.state.token,
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                this.setState({
                    loading: false
                })
                if(response.data.message === "error"){
                    this.setState({
                        msgAr:" كود التفعيل غير صالح ",
                        msgEn:"Verification code not valid",
                        errorMsgModel:true
                    });
                }else{
                    this.displaySuccess()
                }
            }).catch(function (error) {
                this.setState({
                    msgAr: error.response.data.error,
                    msgEn:error.response.data.error,
                    errorMsgModel:true
                });
            })
        }
    };

    handelPointsSubmit = (e) => {
        e.preventDefault();
        let values =
        {
            points: this.state.userPoint,
        };

        axios.post(window.$urlName + 'points/change-points', values, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            this.setState({
                loading: false,
                msgAr: "تم استبدال النقاط بنجاح الي محفظتك, صالحة لمدة يومين",
                msgEn: "Points have been successfully redeemed to your wallet, valid for two days",
                errorMsgModel:true
            })
        }).catch(function (error) {
            this.setState({
                msgAr: error.response.data.error,
                msgEn:error.response.data.error,
                errorMsgModel:true
            });
        }.bind(this));
    };

    render() {
        if (this.state.loggedIn === false) {
            // const location =
            //     {pathname: '/sign-in', state: {from: 'loyalty'}};
            // this.props.history.push(location);
        }

        let formDisplay = {
            display: 'none'
        };

        return (
            <Fragment>

                {this.state.loading ?
                    <div className="loadingSec">
                        <div style={{height: "400px"}}>
                            <div className="parent">
                                <div className="child">
                                    <BallBeat
                                        color={'white'}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>

                    </div> : null}

                <form onSubmit={this.handelSubmit} id="loyaltyForm">
                    <div className="pointRegisterForm">
                        <h1><Translate content="signUp"/></h1>


                        <div className="form-group">
                            <label htmlFor="name"><Translate content="name"/></label>
                            <input name="name" type="text" className="form-control" id="name"
                                   onChange={this.handelInput} value={this.state.name} required/>

                        </div>

                        {!this.state.token ?
                        <div className="form-group">
                            <label htmlFor="phone"><Translate content="phone"/></label>
                            <input name="phone" type="text" className="form-control" id="phone"
                                   onChange={this.handelInput} value={this.state.phone} required/>

                        </div>:null}

                        <div className="form-group">
                            <label htmlFor="email"><Translate content="email"/></label>
                            <input name="email" type="email" className="form-control" id="email"
                                   onChange={this.handelInput} value={this.state.email} required/>

                        </div>

                        <div className="form-group">
                            <label htmlFor="birthDate"><Translate content="birth_date"/></label>
                            <input name="birthDate" type="date" className="form-control" id="birthDate"
                                   onChange={this.handelInput} value={this.state.birthDate} required/>

                        </div>


                        <div className="form-group">
                            <label><Translate content="choose_Nationality"/></label>
                            <Select required="required"
                                    options={this.state.arabCountry}
                                    value={this.state.value}
                                    onChange={this.changeHandler}
                            />
                        </div>


                        <div className="row genderRadio">
                            <div className="col-lg-4 col-md-4">
                                <Translate content="gender" component="p"/>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="radio">
                                    <input type="radio" value="male" id="male"
                                           checked={this.state.selectedOption === 'male'}
                                           onChange={this.handleOptionChange}/>
                                    <label htmlFor="male"> <Translate content="male"/></label>


                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="radio">
                                    <input type="radio" value="female" id="female"
                                           checked={this.state.selectedOption === 'female'}
                                           onChange={this.handleOptionChange}/>
                                    <label htmlFor="female"> <Translate content="female"/></label>
                                </div>
                            </div>
                        </div>


                        <div className="row checkboxApproved">
                            <label>
                                <input type="checkbox" value="approved" id="approved"
                                       checked={this.state.selectedCheckboxOption === 'approved'}
                                       onChange={this.handleOptionCheckboxClick} />
                                <label htmlFor="approved"> <Link to="/terms"><Translate content="approved_conditions"/></Link></label>

                            </label>
                        </div>

                        <button type="submit" className="orangeButton" disabled={!this.state.disableButt}><Translate
                            content="signUp"/></button>
                    </div>
                </form>

                <div className="pointRegisterForm" id="successForm" style={formDisplay}>
                    <p><Translate content="success_awafi_signup"/></p>
                </div>

                <div className="pointRegisterForm" id="verificationForm" style={formDisplay}>
                    <form onSubmit={this.handelVerificationSubmit}>
                        <div className="pointRegisterForm">
                            <h1><Translate content="enter_code"/></h1>

                            <div className="form-group">
                                <label htmlFor="verificationCode"><Translate content="code"/></label>
                                <input name="verificationCode" type="text" className="form-control" id="verificationCode"
                                    onChange={this.handelInput} value={this.state.verificationCode} required/>
                            </div>

                            <button type="submit" className="orangeButton"><Translate
                                content="confirm"/></button>
                        </div>
                    </form>
                </div>

                <div className="pointRegisterForm" id="pointsForm" style={formDisplay}>
                    <h1><Translate content="points_credit"/></h1>
                    <div className="walletCredit">
                        <div className="loadingSec">
                            <BallBeat
                                color={'#2C2C2C'}
                                loading={this.state.loading}
                            />
                        </div>
                        <p>{this.state.pointsBalance}

                            <span><Translate content="points"/></span></p>
                    </div>

                    <form onSubmit={this.handelPointsSubmit}>
                        <div className="inputPoints">
                            <div className="row">
                                <div className="col-lg-5 col-md-5">
                                    <label><Translate content="points"/></label>

                                    <input type="number" min="0" className="form-control" id="userPoint"
                                           name="userPoint"
                                           value={this.state.userPoint} onChange={this.handelInput} required/>
                                </div>
                                <div className="col-lg-2 col-md-2">
                                    <span>=</span>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <label><Translate content="SAR"/></label>

                                    <div className="convertedPoints">
                                        <p>{(this.state.userPoint * this.state.pointPrice).toFixed(2)}</p></div>
                                </div>

                            </div>
                            <Translate component="h3" content="visit_branch"/><Link to="/branches" className="closeLink"><Translate content="close_branch"/></Link>

                        </div>
                        <button type="submit" className="orangeButton" disabled={!this.state.disableButt}><Translate
                            content="change_points"/></button>
                    </form>


                </div>
                
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>

            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authounticateUser: () => {
            dispatch(authounticateUser())
        },
    }
};
export default connect(null, mapDispatchToProps)(Loyalty)
