import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import redirect from "./component/assest/img/redirect.png";
import axios from "axios";

class AgentPay extends Component {
    async componentDidMount() {
        let orderId = window.location.href.split('/')[4];
        axios.get(window.$urlName + 'orders/' + orderId + '/payment-url', {
            headers: {
                // 'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'Accept': 'application/json',
                'Content-Type': 'application/json',                
            }
        }).then((response) => {
            window.location.href = response.data.data.url
            this.props.RESETAPP()
        }).catch(function (error) {
            alert(error.response);
        });
    }

    render() {
        return (
            <Fragment>
                <div style={{height: "400px"}}>
                    <div className="parent">
                        <div className="child">
                            <div className="paySuccessBody" id="paySuccessBody">
                                <img src={redirect} alt="redirect"/>
                                <Translate content="redirectMessage" component="h2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default AgentPay