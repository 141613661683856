import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from 'react-router-dom';
import {BallBeat} from "react-pure-loaders";
import axios from "axios";

class PromotionsKSA extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingSwip: true,
            banners: [],
        }
    }

    componentDidMount() {
        this.setState({
            loadingSwip:true
        })
        axios.get(window.$urlName + 'banners', {
            params: {
                type: 'promotionsKSA',
            }
        }).then((response) => {
            this.setState({
                banners: response.data.data,
                loadingSwip: false,
            });
        });
    }

    render(){
        let banners =
        this.state.banners.map((banner, index) => {
            return (
                <div className="termsBody">
                    <img src={banner.image} alt="vat" width="100%" key={index}/>,
                    <a style={{marginTop:"30px"}} href={banner.link} id="sign-in-button" className="orangeButton">
                        <Translate content="downloadAppNow"/></a>
                </div>
            )
        });
        return(
            <Fragment>
                {this.state.banners.length === 0 ? (
                <div className="loadingSec" style={{marginTop: "200px", marginBottom: "200px"}}>
                    <BallBeat
                        color={'white'}
                        loading={this.state.loadingSwip}
                    />
                </div>
                ) :
                <div className="container">
                    {banners}
                </div>}
            </Fragment>
        )
    }
}
export default PromotionsKSA
