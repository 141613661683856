import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import pluseImg from "./component/assest/img/pluse.png";
import minasImg from "./component/assest/img/minas.png";
import deleteIcon from "./component/assest/img/deleteIcon.png";

import {BallBeat} from "react-pure-loaders";

let translate = require('counterpart');

class MudiafBasket extends Component {
    constructor(props) {
        super(props)
        let loggedIn = true;

        this.state = {
            token: localStorage.getItem("token"),
            loading:false,

            address: "",
            notes: "",
            enCurrency: "SAR",
            arCurrency: "ريال",

            inquireMeals: (localStorage.getItem("inquireMeals")) ? localStorage.getItem("inquireMeals") : [],

            inquireSuccessMsg: localStorage.getItem("inquireSuccessMsg"),

            loggedIn
        }
    }

    componentDidMount() {
        if(localStorage.getItem("inquireSuccessMsg") === "true"){
            localStorage.setItem("inquireSuccessMsg", "false");
        }
    }

    handleAddQuantity = (mealId,mealQuantity) => {
        let arr = JSON.parse(localStorage.getItem("inquireMeals") || "[]");
        let existedLocalStorage = arr.findIndex(item => mealId === item.id);
        arr[existedLocalStorage].quantity += 1;
        localStorage.setItem("inquireMeals", JSON.stringify(arr));
        this.setState({
            inquireMeals: JSON.stringify(arr),
        });
    };

    handleSubtractQuantity = (mealId,mealQuantity) => {
        if(mealQuantity>1){
            let arr = JSON.parse(localStorage.getItem("inquireMeals") || "[]");
            let existedLocalStorage = arr.findIndex(item => mealId === item.id);
            arr[existedLocalStorage].quantity -= 1;
            localStorage.setItem("inquireMeals", JSON.stringify(arr));
            this.setState({
                inquireMeals: JSON.stringify(arr),
            });
        }
    };

    handleRemove = (mealId) => {
        let arr = JSON.parse(localStorage.getItem("inquireMeals") || "[]");
        let existedLocalStorage = arr.findIndex(item => mealId === item.id);
        arr.splice(existedLocalStorage, 1);
        localStorage.setItem("inquireMeals", JSON.stringify(0));
        localStorage.setItem("inquireMeals", JSON.stringify(arr));
        this.setState({
            inquireMeals: JSON.stringify(arr),
        });
    };

    inquireSubmit = (values) => {
        axios.post(window.$urlName + 'mdiaf/inquires', values, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.state.token,
            }
        }).then((response) => {
            localStorage.setItem("inquireSuccessMsg", "true");
            localStorage.setItem("inquireMeals", "[]");
            this.setState({
                redirect: true,
            })
        }).catch(error => {
            if (error.response.status === 401) {
                this.setState({
                    loggedIn: false,
                })
            } else {
                this.getError(error.response.data.error)
            }
        });
    };

    handelSubmit = (e) => {
        e.preventDefault();
        if (this.state.token == null) {
            this.setState({
                loggedIn: false
            })
        }else {
            let values = {
                notes: this.state.notes,
                address: this.state.address,
                meals: this.state.inquireMeals,
            };
            if(this.state.notes !== "" && this.state.address !== "" && JSON.parse(this.state.inquireMeals).length > 0){
                this.inquireSubmit(values);
            }else{
                alert("يرجي اختيار الوجبات لكي تتمكن من الطلب");
            }
        }
    };

    handleChange = (e) => {
        this.setState ({
            [e.target.id]: e.target.value
        });
    };

    getError = (msg) => {
        this.setState({
            ErrorMsgAr: "حدث خطأغير متوقع",
            ErrorMsgEn: "something went wrong",
            orderErrorMsgModel: true,
            loading: false,            
        })
    };
    
    render() {
        if (this.state.loggedIn === false) {
            const location =
                {pathname: '/sign-in', state: {from: 'mudiafInquires'}};
            this.props.history.push(location);
        }

        const {redirect} = this.state;

        if (redirect) {
            window.location.pathname = "/medhyaf-basket"
        }

        let meals = JSON.parse(this.state.inquireMeals).map((meal) => {
            return (
                <div className="row mealCard"  key={meal.id}>
                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                        <button className="removeButton" onClick={() => {
                            this.handleRemove(meal.id)
                            }}><img src={deleteIcon} alt="icon"/>
                        </button>
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                        <div className="branchImg" style={{backgroundImage: `url(${meal.mealImage})`}}>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                        <div className="branchSingleCardTitle">
                            {translate.getLocale() === 'ar' ? <h3>{meal.mealArName}</h3> : <h3> {meal.mealEnName} </h3>}
                            {translate.getLocale() === 'ar' ? <p>{meal.mealArDescription}</p> : <p>{meal.mealEnDescription}</p>}
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <div className="quantitySec add-remove">
                            <button type="button" onClick={() => {
                                    this.handleAddQuantity(meal.id,meal.quantity)                                
                                }} className="plus"><img src={pluseImg} alt="plus"/>
                            </button>

                            <b>{meal.quantity}</b>

                            <button type="button" onClick={() => {
                                    this.handleSubtractQuantity(meal.id,meal.quantity)
                                }} className="min"><img src={minasImg} alt="min"/>
                            </button>
                        </div>
                        <div className="categoryPrice">
                            <p className="arialFont"> {meal.quantity*meal.mealOriginalPrice} {translate.getLocale() === 'ar' ?
                                <span>{this.state.arCurrency}</span> :
                                <span>{this.state.enCurrency}</span>}</p>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <Fragment>

                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf`}}>
                                <Translate content="mudiaf_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-services`}}>
                                <Translate content="mudiaf_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items mudiaf-items-active">
                            <Link to={{pathname: `/medhyaf-inquires`}}>
                                <Translate content="mudiaf_inquires"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-images`}}>
                                <Translate content="mudiaf_images"/>
                            </Link>
                        </div>
                                  <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-contact`}}>
                                <Translate content="mudiaf_contact"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <div className="container">
            
                        {this.state.inquireSuccessMsg === "true" ? 
                            <div className="row">
                                <div className="mudiaf-inquire-success">
                                    <Translate component="p" content="inquireSuccess"/>
                                </div>
                            </div>
                        : 

                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <Translate component="p" className="mudiaf-inquireP" content="inquireParagraph"/>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 backClass">
                                <Link to={{pathname: `/medhyaf-inquires`}}>
                                    <Translate content="backToMenu"/>
                                </Link>
                            </div>
                            <form onSubmit={this.handelSubmit} style={{width:"60%",minWidth:"320px",margin:"auto"}}>
                                <div className="mudiafCheckoutWhite" style={{padding:"30px",borderRadius:"10px"}}>

                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{position: "relative"}}>
                                            {meals}
                                        </div>
                                    </div>

                                    <div className="row" style={{marginTop:"20px"}}>
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <Translate attributes={{placeholder: 'enterAddress'}} id="address" defaultValue={this.state.address} onChange={this.handleChange} component="textarea" required="required"/>
                                        </div>
                                    </div>

                                    <hr/>

                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <Translate attributes={{placeholder: 'enter_your_notes'}} id="notes" defaultValue={this.state.notes} onChange={this.handleChange}  component="textarea" required="required"/>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="paymentButton" style={{margin:"15px auto auto"}}>
                                                <button type="submit"><Translate content="send"/></button>
                                            </div>
                                        </div>
                                    </div>

                    
                                </div>
                            </form>
                        </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default MudiafBasket