import React, {Component, Fragment} from 'react';
import {Link,NavLink} from 'react-router-dom';
import { NavHashLink  } from 'react-router-hash-link';
import counterpart from "counterpart";
import Translate from "react-translate-component";
// import phone from "./component/assest/img/phone.png";
import logo from "./component/assest/img/logoAR.png";
import logoEn from "./component/assest/img/logoEn.png";
import insta from "./component/assest/img/instagram.svg";
import facebook from "./component/assest/img/facebook.svg";
import youtube from "./component/assest/img/youtube.svg";
import twitter from "./component/assest/img/twitter.svg";
import snaptube from "./component/assest/img/snapchat.svg";
import basket from "./component/assest/img/basket.svg";
import star from "./component/assest/img/star.svg";
import food from "./component/assest/img/food.svg";
import profile from "./component/assest/img/profile.svg";
import {faArrowsAltV} from "@fortawesome/free-solid-svg-icons";
import {library} from '@fortawesome/fontawesome-svg-core';
import Sticky from "react-sticky-el";
import App from "./App";
import connect from "react-redux/es/connect/connect";

library.add(faArrowsAltV);
let ReactDOM    = require('react-dom');

class Header extends Component {
    constructor(props) {
        super(props);
        let loggedIn = true;

        this.state = {
            token: localStorage.getItem("token"),
            menu: false,
            fastDelivery :'',
            ordinaryDelivery :'',
            deliveryPrice:'',
            promo:'',
            descountPrice:'',
            basket : basket,
            star : star,
            food : food,
            profile: profile,
            redirect: false,
            selectedOptiontime: 'true',
            notes: '',
            datePickerIsOpen: false,
            startDate: new Date(),
            now:'',
            date:'',
            time:'',
            logoutModel: false,
            // profileShow:false,
            loggedIn


        };


    }
    componentDidMount(){
        if(this.props.authorized === true){
            this.forceUpdate()
        }
    }

    checkRoute = () => {
        if(localStorage.getItem("branches") !== null && localStorage.getItem("categories") !== null){
            if (localStorage.getItem("menuRoute") === "true"){
                window.location.pathname="/food-list"

            }else {
                window.location.pathname="/"
            }

        }else{
            window.location.pathname="/"
        }
    };


    onLangChange = (e) => {
        this.setState({lang: e.target.value});
        counterpart.setLocale(e.target.value);
        if(counterpart.setLocale(e.target.value) === 'en') {
            localStorage.setItem("lang","en");
            window.location.reload();
            window.onload = function() {
                ReactDOM.render(<App />, document);
            };

        } else if(counterpart.setLocale(e.target.value) === 'ar') {
            localStorage.setItem("lang","ar");
            window.location.reload();
            window.onload = function() {
                ReactDOM.render(<App />, document);
            };

        }
    };






    render() {
        return (
            <Fragment>
                <Sticky stickyClassName="stickyNav">

                <div className="container-fluid mainNav">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-4">
                                <div className="parent">
                                    <div className="child">
                                        <div className="navLogo">
                                            <Link to="/">
                                                {localStorage.getItem("lang") === "en" ? <img src={logoEn} alt="logo"/> :<img src={logo} alt="logo"/>}

                                                </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-md-8">
                                <div className="navLinkDiv">
                                    <div className="socialNavbar">
                                        <div className="row">
                                            {/*<a href="tel:1 (412) 381-5500" className="telNav"><img src={phone} alt="phone"/>920000144</a>*/}


                                            <a href="https://www.snapchat.com/" target="_blank" rel="noopener noreferrer" className="socialIcon"><img src={snaptube} alt="snaptube"/>
                                            </a>
                                            <a href="https://www.facebook.com/alromansiahksa/" target="_blank" rel="noopener noreferrer" className="socialIcon"><img src={facebook} alt="facebook"/>
                                            </a>

                                            <a href="https://www.youtube.com/user/alromansiah" target="_blank" rel="noopener noreferrer" className="socialIcon"><img src={youtube} alt="youtube"/>
                                            </a>
                                            <a href="https://www.instagram.com/alromansiahksa/" target="_blank" rel="noopener noreferrer" className="socialIcon"><img src={insta} alt="instgram"/>
                                            </a>
                                            <a href="https://twitter.com/alromansiahksa" target="_blank" rel="noopener noreferrer" className="socialIcon"><img src={twitter} alt="twitter"/>
                                            </a>
                                            <select onChange={this.onLangChange} value={localStorage.getItem("lang")?localStorage.getItem("lang"):"ar"}
                                                    className="langButton">
                                                <option value="en">EN</option>
                                                <option value="ar">AR</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="navLinks">
                                            {!localStorage.getItem("token")?<div className="NavLink"> <NavLink activeClassName='is-active' to="/sign-in"><Translate content="signIn" className="navTExt"/></NavLink></div> :
                                            <div className="NavLink"><NavLink activeClassName='is-active' to="/profile"><div className="navProfileImg"></div><span className="arialFont">{localStorage.getItem("userName")?localStorage.getItem("userName").substring(0,10):null}</span></NavLink></div> }
                                            <div className="NavLink"><NavLink activeClassName='is-active' to="/branches"><Translate content="branches" className="navTExt"/></NavLink></div>
                                            <div className="NavLink"><NavLink activeClassName='is-active' to="/medhyaf"><Translate content="hospitable" className="navTExt"/></NavLink></div>
                                            <div className="NavLink"><NavLink activeClassName='is-active' to="/companies"><Translate content="companiesSubscriptions" className="navTExt"/></NavLink></div>
                                            <div className="NavLink"><NavLink activeClassName='is-active' to="/top-customers"><Translate content="topCustomers" className="navTExt"/></NavLink></div>
                                            <div className="NavLink"><NavLink activeClassName='is-active' to="/awafi"><div className="navLoyaltyImg"></div> <Translate content="loyalty" className="navTExt"/></NavLink></div>
                                            <div className="NavLink"><NavLink activeClassName='is-active' to="/basket"><div className="navBasketImg"></div><Translate content="basket" className="navTExt"/> </NavLink></div>
                                            <div className="NavLink"><NavHashLink  activeClassName='is-active' to="#" onClick={this.checkRoute}><div className="navMenuImg"></div><Translate content="menu" className="navTExt"/></NavHashLink></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </Sticky>




            </Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        authorized: state.authorized,
    }
};
export default connect(mapStateToProps, null)(Header)

