import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from 'react-router-dom';

class Terms extends Component {
    render(){
        return(
            <Fragment>
                <div className="container">
                    <div className="termsBody">
                        <Translate content="terms" component="h1"/>
                        <hr/>
                        <p>
                            برنامج عوافي الخاص بشركة الرومانسية المحدودة، هو برنامج مكافآت تديره شركة الرومانسية المحدودة وبصفتك عضو ستحظى بنقاط مكافأت عند قيامك بالشراء من مطاعم الرومانسية وذلك عند إظهار رقم عوافي الخاصة بك عند الشراء. هذه الشروط والأحكام تتعلق بالعضوية واكتساب واستبدال قيمة النقاط المكتسبة تبعا للبرنامج عند التسجيل في عوافي.
                            1- باستخدام رقم عوافي الخاص بك في أي من فروع الرومانسية بغرض كسب النقاط فأنت تلقائياً تعطي موافقتك على البرنامج والبنود والشروط الخاضعة للتعديل من وقت إلى آخر.

                            2- يحق لشركة الرومانسية المحدودة أن تقوم بسحب أو إلغاء أي أو جميع عضويات عوافي الصادرة ورفض منح النقاط وسحبها، ورفض حق استرداد قيمة النقاط المكتسبة دون إشعار مسبق. وذلك نتيجة أي خرق لهذه الشروط والأحكام أو عدم سداد قيمة المشتريات

                            3- إن عضويتك في البرنامج يمكن أن تُسحب أو تُرفض إذا كنت طرفا في أي عملية تزوير أو سرقة أو غش بسبب أو بدون سبب ويتم هذا السحب أو الرفض دون إشعار. ويمكن إلغاء العضوية أيضاً إذا تبين بأنك كنت طرفا في أي سوء استعمال لعضوية برنامج عوافي. في حال حدوث ذلك فجميع نقاطك الموجودة في حساب عضويتك سوف تعتبر ملغاة ولن تتمكن من استعادة قيمتها مرة أخرى.

                            4- يحق لشركة الرومانسية أن تقوم بتعديل أو وقف البرنامج أو تغيير أو إلغاء أو

                            سحب أي من الشروط والأحكام حسب تقديرها ودون الحاجة لإعطاء أي سبب من الأسباب في أي وقت من الأوقات.

                            5- يحق لشركة الرومانسية المحدودة إيقاف العضوية في البرنامج - الحالية أو الجديدة - لفترة مؤقتة أو دائمة . وستعمل شركة الرومانسية المحدودة جاهدة لتحديث ذلك وإضافة معلومات دقيقة على موقعها www.alromansiah.com أو تطبيق الرومانسية حول هذا التغيير أو الإلغاء أو الإضافة أو السحب أو التعديل. تقع عليك مسئولية قراءة تلك البنود والشروط بتمعن وقبولها.

                            6- استمرارية عضويتك يعني قبولك بالالتزام بكل البنود والشروط المطبقة من وقت لآخر. وفي حال عدم موافقتك على أي من البنود والشروط فإننا ننصحك بسحب وإلغاء عضويتك.

                            7- يمكن للمزايا والعروض المقدمة إليك من خلال البرنامج أن تتغير أو تُسحب دون إعلان مسبق عن ذلك. ولن تتحمل شركة الرومانسية المحدودة أي مسؤولية نتيجة هذه الإجراءات.

                            8- يحق لشركة الرومانسية المحدودة أن تعدل الطريقة التي تُكتسب فيها النقاط أو استرداد قيمتها بما في ذلك عدد النقاط المكتسبة وقيمة هذه النقاط.

                            9- أنت مسئول عن إعلام مركز خدمة أعضاء عوافي عن أي تغيير في عنوانك أو تفاصيل طرق الاتصال بك ولن تكون شركة الرومانسية مسئولة عن أي استعمال غير قانوني أو سوء استعمال لعضوية عوافي.

                            10- عند اشتراكك في برنامج عوافي، فإنك تمنح شركة الرومانسية المحدودة موافقتك الغير مشروطة لجمع واستخدام والاحتفاظ بالمعلومات الموجودة في نموذج طلب عضوية عوافي وكذلك معلوماتك الشخصية الأخرى بما في ذلك وبدون حصر بريدك الالكتروني وعناوينك وأرقام الاتصال بك وتاريخ الميلاد وتفاصيل مشترياتك وما شابه، وذلك للأغراض التالية: -

                            أ. للتأكد من فعالية عمل البرنامج واستحقاق واستبدال قيمة النقاط.

                            ب. لتقديم معلومات حول برنامج عوافي.

                            ت. لتطوير/ لتقديم منتجات وخدمات جديدة.

                            ث. لأغراض المحاسبة والتدقيق المالي.

                            ج. لأغراض التسويق وأبحاث وتحليل سلوك المستهلك.

                            ح. لغرض الشفافية كما هو مطلوب قانونياً.

                            خ. لإبلاغك وإرسال العروض والخدمات والمنتجات والمميزات المقدمة من شركة الرومانسية المحدودة.

                            د. للمساعدة في تخطيط وتطوير وتشغيل البرنامج.

                            11- موافقتك المذكورة سوف تستمر فعّالة حتى تقوم بسحب الموافقة وذلك بالاتصال على مركز الاتصال 920000144. سحب الموافقة يعني عدم استمرار الخدمة المقدمة لك وأيضا تخويلا لشركة الرومانسية لإلغاء عضويتك بصورة فردية.

                            12- شركة الرومانسية المحدودة لن تكون مسئولة قانونياً عن أي خسارة أو ضرر، سواء بصورة مباشرة أو غير مباشرة نتيجة للإلغاء أو التغيير في البرنامج أو أي المميزات أو الفوائد المتاحة للأعضاء.

                            13- تعتبر الشروط والأحكام المنشورة من وقت لآخر في موقع شركة الرومانسية الالكتروني www.alromansiah.com نهائية وملزمة وهي تبطل هذه الشروط والأحكام والمعلومات الأخرى المقدمة هنا عن البرنامج.

                            14- في حال وجود عطل فني في النظام، وفقدان جميع أو بعض بيانات العملاء لأي سبب من الأسباب، فإن شركة الرومانسية المحدودة لا تتحمل أي مسؤولية تجاه ذلك.

                            ملاحظة : في حال تم تفعيل البرنامج في فروع خارج المملكة :-

                            15- هذه الشروط والأحكام تحكمها قوانين الدولة التي تصدر بطاقة عضوية عوافي فيها وأي نزاع يمكن إحالتها إلى محاكم هذه الدولة.
                        </p>
                        <Link to="/awafi" className="backLinkTerms"><Translate content="back"/></Link>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Terms
