import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import {Link} from "react-router-dom"
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import { BallBeat } from 'react-pure-loaders';
import eye from "./component/assest/img/eye.svg";
import {Redirect} from 'react-router-dom';
import {authounticateUser} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";
import {Modal, ModalBody} from "reactstrap";
let translate = require('counterpart');


class SignIn extends Component {
    constructor(props) {
        super(props);
        let loggedIn = false;
        this.state = {
            token: '',
            loading: false,
            hidden: true,
            verified:false,
            isOldRedirect:false,
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,

            loggedIn

        };

    }
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    startLoading = () =>{
            this.setState({
            loading:true,

        })
    };
    toggleShow = () => {
        this.setState({ hidden: !this.state.hidden });
    }

    render() {
      let a=document.createElement('a');
        a.href=document.referrer;
        let prevLink = a.pathname;
        if (this.state.loggedIn){
            if (prevLink === "/sign-up"){
                this.props.authounticateUser();
                window.location.pathname = "/"
            }else if(prevLink === "/forget-password"){
                this.props.authounticateUser();
                window.location.pathname = "/"
            }
            else {
                this.props.authounticateUser();
                this.props.history.go(-1)

            }

        }
        if (this.state.verified) {
            return <Redirect to="/verification-code"/>

        }
        if (this.state.isOldRedirect) {
            return <Redirect to="/user-code"/>

        }




        let phoneRegEx = /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
        // let phoneRegEx = /^(?=(?:\D*\d){8,10}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/;

        return (
            <Fragment>
                <div className="container">
                    <div className="signForm">
                        <Formik
                            initialValues={{
                                phone: '',
                                password: '',
                            }}
                            validationSchema={Yup.object().shape({

                                phone: Yup.string()
                                    .matches(phoneRegEx, <Translate content="phone_not_valid"/>)
                                    .required(<Translate content="phone_required"/>),
                                password: Yup.string()
                                    .min(6, <Translate content="at_least"/>)
                                    .required(<Translate content="password_required"/>),

                            })}
                            onSubmit={values => {
                                this.setState({
                                    loading:true
                                })
                                axios.post(window.$urlName + 'customers/get-token',values, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    }

                                }).then((response) => {
                                    if (response.data.data.isOld === true) {
                                        localStorage.setItem("token", response.data.data.access_token);
                                        localStorage.setItem("userPhone", values.phone)
                                        this.setState({
                                            isOldRedirect: true
                                        })
                                    } else {
                                    if (response.data.data.isVerified === true) {
                                        localStorage.setItem("token", response.data.data.access_token);
                                        localStorage.setItem("userPhone", values.phone)
                                        axios.get(window.$urlName + 'customers/account-info', {
                                            headers: {
                                                'Authorization': 'Bearer ' + response.data.data.access_token,
                                                'Accept': 'application/json'
                                            }
                                        }).then((response) => {
                                            localStorage.setItem("userName", response.data.data.name)
                                            this.setState({
                                                token: response.data.data.access_token,
                                                loggedIn: true,
                                                loading: false,

                                            })

                                        })


                                    } else {

                                        localStorage.setItem("token", response.data.data.access_token);
                                        this.setState({
                                            msgAr:"لم يتم تأكيد هذا الحساب من فضلك ادخل رمز التأكيد",
                                            msgEn:"This account is not verified please enter your code",
                                            errorMsgModel:true
                                        });

                                        let headers = {
                                            'Authorization': 'Bearer ' + response.data.data.access_token,
                                            'Accept': 'application/json'
                                        };
                                        axios.get(window.$urlName + 'customers/phone-verification-SMS-send', {headers}).then((response) => {
                                            this.setState({
                                                verified: true
                                            })
                                            // console.log(response.data)
                                        }).catch(function (error) {
                                            // console.error(error);
                                        });

                                    }

                                }




                                }).catch(function(error){
                                    if (error.response.status === 401 || error.response.status === 422){
                                        this.setState({
                                            loading: false,
                                            msgAr:"خطأ في رقم الهاتف او كلمة المرور",
                                            msgEn:"Invalid phone or password",
                                            errorMsgModel:true
                                        });
                                    } else{
                                        this.setState({loading: false});

                                    }
                                }.bind(this))



                            }}

                            render={({errors, status, touched}) => (
                                <Form>
                                    <Translate content="signIn_register" component="h1"/>
                                    <div className="loadingSec">

                                    <BallBeat
                                        color={'#2C2C2C'}
                                        loading={this.state.loading}
                                    />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone"><Translate content="phone"/></label>
                                        <Field name="phone" id="phone" type="tel"
                                               className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')} />
                                        <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password"><Translate content="password"/></label>
                                        <div style={{position:"relative"}}>

                                        <Field name="password" id="password" type={this.state.hidden ? "password" : "text"}
                                               className={'form-control' +  (errors.password && touched.password ? ' is-invalid' : '')}/>
                                        <span className="showButt" onClick={this.toggleShow}><img src={eye} alt="eye"/></span>
                                        <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                                    </div>
                                    </div>
                                    <Link to="/forget-password" className="forgetPrg"> <Translate
                                        content="forget_password"/></Link>
                                    <button type="submit" className="grayButton"><Translate content="start_alromansiah"/></button>
                                    <Translate content="dont_have_account" component="p"/>
                                    <button type="button"><Link to="/sign-up"><Translate content="signUp"/></Link>
                                    </button>

                                </Form>
                            )}
                        />
                    </div>
                </div>

                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>

        )
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        authounticateUser: () => {
            dispatch(authounticateUser())
        },


    }
};
export default connect(null, mapDispatchToProps)(SignIn)
