import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import axios from "axios";
import moneyIcon from "./component/assest/img/moneyIcon.svg";
import personIcon from "./component/assest/img/personIcon.svg";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-el";
import MudiafBanner from "./mudiafBanner";

import {BallBeat} from "react-pure-loaders";

let translate = require('counterpart');

class MudiafMenus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menus: [],
            currAr: "ريال",
            currEn: "SAR",
            loading:false,
        }
    }

    componentDidMount() {
        this.setState({
            loading:true
        })
        let headers = {
            'Accept': 'application/json',
        };
        axios.get(window.$urlName + 'mdiaf/menus', {
            headers
        }).then((response) => {
            this.setState({
                menus: response.data.data.mdiafMenus,
                loading:false
            })
        }).catch(function (error) {
             //console.log(error.response)
        });
    }

    render() {
        let menus = this.state.menus.map((menu) => {
            return (

                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={menu.id}>
                    <div className="branchSingleCard">

                        <div className="branchImg" style={{backgroundImage: `url(${menu.image})`}}>
                        </div>
                        {translate.getLocale() === 'ar' ? <h3>{menu.arName}</h3> :
                            <h3>{menu.enName}</h3>}
                        <p> <img src={moneyIcon} alt="money"/> {menu.pricePersonSAR}
                            {translate.getLocale() === 'ar' ? <span>{this.state.currAr}</span> : <span>{this.state.currEn}</span>} <img src={personIcon} style={{width:"13px"}} alt="person"/> <Translate content="min_persons" component="span"/> {menu.minPersonsNumber} <Translate content="person" component="span"/></p>

                        <Link to={{pathname: `/medhyaf-menu/${menu.id}`}}>
                            <button type="button" className="callDerive" style={{background:"#DB095B"}}>
                                    <Translate content="openMenu"/>
                            </button>
                        </Link>
                    </div>

                </div>
            )
        });
        return (
            <Fragment>
                <MudiafBanner></MudiafBanner>
                <Sticky stickyClassName="stickyFilter">
                    <div className="mudiaf-bar">
                        <div className="mudiaf-items mudiaf-items-active">
                            <Link to={{pathname: `/medhyaf`}}>
                                <Translate content="mudiaf_menus"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-services`}}>
                                <Translate content="mudiaf_services"/>
                            </Link>
                        </div>                        
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-inquires`}}>
                                <Translate content="mudiaf_inquires"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-images`}}>
                                <Translate content="mudiaf_images"/>
                            </Link>
                        </div>
                        <div className="mudiaf-items">
                            <Link to={{pathname: `/medhyaf-contact`}}>
                                <Translate content="mudiaf_contact"/>
                            </Link>
                        </div>
                    </div>
                </Sticky>

                <div className="container-fluid branchesSec">
                    <div className="loadingSec">
                        <BallBeat
                            color={'#2C2C2C'}
                            loading={this.state.loading}
                        />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="hospitableInfo">
                                <Translate content="hospitable" component="h1"/>
                                <Translate content="about_hospitable" component="p"/>
                            </div>

                        </div>
                        <div className="row">
                            {menus.length>0 ? menus : 
                                <Translate content="noResults" component="p" className="no_results"/>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default MudiafMenus