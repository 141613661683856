import React, {Component, Fragment} from 'react';
import Translate from "react-translate-component";
import eye from './component/assest/img/eye.svg'
import {Formik, Field, Form, ErrorMessage} from 'formik';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import {BallBeat} from "react-pure-loaders";
import {RESETAPP} from "./actions/cartActions";
import connect from "react-redux/es/connect/connect";
import {Modal, ModalBody} from "reactstrap";

let translate = require('counterpart');

class SignUp extends Component {
    constructor(props) {
        super(props);
        let loggedIn = false;
        this.state = {
            token: '',
            countries: [],
            loading: false,
            redirect: false,
            hidden: true,
            msgAr:"",
            msgEn:"",
            errorMsgModel: false,
            termsModel: false,
            selectedCheckboxOption: "",
            arDesc: "",
            enDesc: "",

            loggedIn
        };

    }

    componentDidMount() {
        axios.get(window.$urlName + 'countries').then((response) => {
            this.setState({
                countries: response.data.data,
            });


        });
        localStorage.removeItem("userName");

    }
    handleOptionCheckboxClick = (changeEvent) => {
        this.setState({
            selectedCheckboxOption: changeEvent.target.value
        });
    };
    errorTypeModel = () => {
        this.setState(prevState => ({
            errorMsgModel: !prevState.errorMsgModel,

        }));

    };
    termsTypeModal = () => {
        axios.get(window.$urlName + 'infos/terms').then((response) => {
            if (response.data.data !== null) {
             this.setState({
                 arDesc: response.data.data.arDescription,
                 enDesc: response.data.data.enDescription
             })
            }

        });
        this.setState(prevState => ({
            termsModel: !prevState.termsModel,
        }));
    };
    toggleShow = () => {
        this.setState({hidden: !this.state.hidden});
    }
    redirectSignIn = () => {
        window.location.pathname = "/sign-in"
    }

    render() {
        const {redirect} = this.state;

        if (redirect) {
            return <Redirect to={{
                pathname: '/verification-code',
                state: {token: this.state.token}
            }}
            />
        }

        let phoneRegEx = /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

        let countries = this.state.countries.map((country) => {
            return (
                translate.getLocale() === 'ar' ? <option value={country.id} key={country.id}>{country.arName}</option> :
                    <option value={country.id} key={country.id}>{country.enName}</option>

            )
        });
        return (
            <Fragment>
                <div className="container">
                    <div className="signForm">
                        <Formik
                            initialValues={{
                                name: '',
                                phone: '',
                                countryId: '',
                                password: '',
                                confirmPassword: '',
                                terms: false
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string()
                                    .required(<Translate content="name_required"/>),
                                countryId: Yup.string()
                                    .required(<Translate content="country_required"/>),
                                phone: Yup.string()
                                    .matches(phoneRegEx, <Translate content="phone_not_valid"/>)
                                    .required(<Translate content="phone_required"/>),
                                password: Yup.string()
                                    .min(6, <Translate content="at_least"/>)
                                    .required(<Translate content="password_required"/>),
                                confirmPassword: Yup.string()
                                    .oneOf([Yup.ref('password'), null], <Translate content="password_must_match"/>)
                                    .required(<Translate content="confirm_required"/>),
                                terms: Yup
                                    .boolean()
                                    .oneOf([true],  <Translate content="terms_must_checked"/>),

                            })}
                            onSubmit={values => {
                                this.setState({
                                    loading: true,
                                });
                                axios.post(window.$urlName + 'customers', values, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json'
                                    }
                                }).then((response) => {
                                        localStorage.setItem("token", response.data.data.access_token);
                                        this.props.RESETAPP();
                                        this.setState({
                                            token: response.data.data.access_token,
                                            redirect: true,
                                            loading: false
                                        })

                                }).catch(function (error) {
                                    if(error.response.data.error === "phone_exist"){
                                        this.setState({
                                            loading: false,
                                            msgAr:"هذا الرقم مسجل من قبل",
                                            msgEn:"This phone is exist",
                                            errorMsgModel:true
                                        });
                                    }else {
                                        this.setState({
                                            loading: false,
                                            msgAr:"حدث خطأ",
                                            msgEn:"Something went wrong",
                                            errorMsgModel:true
                                        });
                                    }

                                }.bind(this))
                            }}

                            render={({errors, status, touched,values}) => (
                                <Form>
                                    <div className="loadingSec">

                                        <Translate content="signUp" component="h1"/>
                                        <BallBeat
                                            color={'#2C2C2C'}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name"><Translate content="name"/></label>
                                        <Field name="name" type="text"
                                               className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone"><Translate content="phone"/></label>
                                        <Field name="phone" type="tel"
                                               className={'form-control' + (errors.phone && touched.phone ? ' is-invalid' : '')}/>
                                        <ErrorMessage name="phone" component="div" className="invalid-feedback"/>
                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="countryId"><Translate content="country"/></label>
                                        <Field component="select" name="countryId"
                                               className={'form-control' + (errors.countryId && touched.countryId ? ' is-invalid' : '')}>
                                            <Translate content="choose_country" component="option" value=""/>
                                            {countries}
                                        </Field>
                                        <ErrorMessage name="country" component="div" className="invalid-feedback"/>

                                    </div>


                                    <div className="form-group">
                                        <label htmlFor="password"><Translate content="password"/></label>
                                        <div style={{position: "relative"}}>
                                            <Field name="password" type={this.state.hidden ? "password" : "text"}
                                                   className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                            <span className="showButt" onClick={this.toggleShow}><img src={eye}
                                                                                                      alt="eye"/></span>
                                            <ErrorMessage name="password" component="div" className="invalid-feedback"/>

                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword"><Translate content="confirm_password"/></label>
                                        <div style={{position: "relative"}}>
                                            <Field name="confirmPassword" type={this.state.hidden ? "password" : "text"}
                                                   className={'form-control' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}/>
                                            <span className="showButt" onClick={this.toggleShow}><img src={eye}
                                                                                                      alt="eye"/></span>
                                            <ErrorMessage name="confirmPassword" component="div"
                                                          className="invalid-feedback"/>

                                        </div>


                                    </div>
                                    <div className="form-group">
                                        <Field type="checkbox" name="terms" id="terms" checked={values.terms}/>
                                        <label htmlFor="terms"><Translate content="agree_terms"/> <Translate style={{textDecoration:"underline",cursor: "pointer"}} onClick={this.termsTypeModal} content="terms_conditions"/></label>
                                        {errors.terms ? <div className="invalidTerms"><span>{errors.terms}</span></div> : null}

                                    </div>
                                    <button type="submit"><Translate content="signUp"/></button>
                                    <Translate content="have_account" component="p"/>
                                    <button type="button" className="grayButton" onClick={this.redirectSignIn}>
                                        <Translate
                                            content="start_alromansiah"/></button>
                                </Form>
                            )}
                        />
                    </div>
                </div>
                <Modal isOpen={this.state.errorMsgModel} toggle={this.errorTypeModel} className="countryModal">
                    <ModalBody>
                        <div className="cancelOrder">
                            <Translate content="sorry" component="p"/>

                            {translate.getLocale() === 'ar' ? <p> {this.state.msgAr}</p> :
                                <p>{this.state.msgEn}</p>}
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.termsModel} toggle={this.termsTypeModal} className="countryModal termsCond">
                    <ModalBody>
                        <div className="termsInfo">
                            <Translate content="terms_conditions" component="h2"/>
                            {translate.getLocale() === 'ar' ?  <div dangerouslySetInnerHTML={{ __html: this.state.arDesc }} />
                                 :
                                <div dangerouslySetInnerHTML={{ __html: this.state.enDesc }} />}
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        RESETAPP: () => {
            dispatch(RESETAPP())
        },
    }
};
export default connect(null, mapDispatchToProps)(SignUp)
