import React, {Component, Fragment} from 'react';
import playStore from "./component/assest/img/playStore.svg";
import appStore from "./component/assest/img/appStore.svg";
import appGallery from "./component/assest/img/appGallery.svg";
import logo from "./component/assest/img/logoAR.png";
import logoEn from "./component/assest/img/logoEn.png";
import vat from "./component/assest/img/vat.svg";
import vatpdf from "./component/assest/img/vat.pdf";
import {Link} from 'react-router-dom';
import Translate from "react-translate-component";

let translate = require('counterpart');

class Footer extends Component {
    render() {
        return (
            <Fragment>
                <div className="container-fluid footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <div className="rightFooterSide">
                                    <Translate content="footer_prg" component="p"/>
                                    <div className="row storeSec">
                                        <div className="col-lg-4 col-md-4">
                                            <a href="https://itunes.apple.com/sa/app/%D8%A7%D9%84%D8%B1%D9%88%D9%85%D8%A7%D9%86%D8%B3%D9%8A%D8%A9/id680481004?mt=8"
                                               target="_blank" rel="noopener noreferrer"><img src={playStore} alt="app_store"/> </a>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <a href="https://play.google.com/store/apps/details?id=com.tech_world.romansia&hl=ar"
                                               target="_blank" rel="noopener noreferrer"><img src={appStore} alt="google_store"/> </a>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <a href="https://appgallery.huawei.com/#/app/C103513027"
                                               target="_blank" rel="noopener noreferrer"><img src={appGallery} alt="google_store"/> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-2 col-md-2">
                                <div className="leftFooterSide">
                                    <Link to="/vat">
                                        <img src={vat} style={{width:"50px"}} alt="vat"/>
                                    </Link>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                                <div className="leftFooterSide">
                                    <Link to="/">
                                        {translate.getLocale() === 'ar' ? <img src={logo} alt="logo"/> :
                                            <img src={logoEn} alt="logo"/>}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="copyWrite"> <Translate content="copy_write_parg" component="span"/><span className="arialFont"> © 2021</span></div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Footer